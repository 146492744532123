<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :labelIcon="labelIcon"
        :info="info"
        :input_icon="input_icon"
        :disabled="disabled"
        :error="error"
        :warning="warning"
    >

        <div class="field-group__input-container">
                <input type="text"
                       tabindex="1"
                       class="input_hidden-password"
                       style="position: absolute; top: -2000px"
                       :disabled="disabled"
                       @focus="onFocus"
                       v-if="optional_hidden_field"
                >
                <input class="field-group__input"
                       :ref="'input'"
                       :readonly="read_only"
                       :autocomplete="false"
                       :class="[fieldGroup_input_class]"
                       :style="{paddingRight: filterCommands.length ? (filterCommands.length*2.5+'rem') : ''}"
                       :disabled="disabled===true"
                       :type="type"
                       :id="id"
                       :name="id"
                       :placeholder="placeholder_text"
                       :value="modelValue"
                       @input="emitValue"
                >
                <div class="field-group__show-value" v-if="filterCommands.length">
                    <template v-for="command in filterCommands" :key="command.id">
                        <button class="button button_command" @click.stop="command.fn()">
                            <SvgIcon :name="command.icon()"/>
                        </button>
                    </template>
                </div>
        </div>

    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_input',
        emits:['update:modelValue'],
        props: {
            'commands': {
                type: Array,
                default: () => ['showPassword']
            },
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'read_only':{
                type: Boolean,
                default: () => false
            },
            'id': String,
            'label': String,
            'labelIcon': String,
            'modelValue': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'warning': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'input_type': String,
            'input_icon': String,
            'disabled': {
                type: Boolean,
                default: () => false
            },
            'modelModifiers': {
                default: () => ({})
            },
            'optional_hidden_field':{
                type: Boolean,
                default: () => false
            },
        },
        data() {
            return {
                showValuePassword: false,
                defCommands: [
                    {
                        id: 'showPassword',
                        icon: () => this.showValuePassword ? 'circle__hidden' : 'circle__shown',
                        fn: () => this.showValuePassword = !this.showValuePassword,
                        view: this.input_type === 'password'
                    },
                    {
                        id: 'clear',
                        icon: () => 'common__close',
                        fn: () => this.$emit('update:modelValue', ''),
                        view: true
                    },
                ]
            }
        },
        computed: {
            filterCommands() {
                return !this.disabled ? this.defCommands.filter(command => this.commands.includes(command.id) && command.view) : []
            },
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label?.toLowerCase()
            },
            type() {
                let type = this.input_type ? this.input_type : 'text'
                if(type === 'password' && this.showValuePassword) {
                    type = 'text'
                }
                return type
            }
        },
        methods: {
            emitValue(e) {
                let value = e.target.value
                if (this.modelModifiers.capitalize) {
                    value = value.charAt(0).toUpperCase() + value.slice(1)
                }
                if (this.modelModifiers.lowercase) {
                    value = value.toLowerCase()
                }
                if (this.modelModifiers.uppercase) {
                    value = value.toUpperCase()
                }
                this.$emit('update:modelValue', value)
            },
            onFocus(e){
                e.target.nextElementSibling.focus()
            },
        },
        mounted() {
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>
<style lang="scss" scoped>
    input[type="number"] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }
    }
</style>
