import axios from 'axios';
import consts from "@/consts"
import config from "@/config"
import store from '@/store'
import i18n from '@/i18n'

let baseURL = config.api.baseURL
const instance = axios.create({
    baseURL,
    headers: {
        "Content-type": "application/json",
    }
})

instance.defaults.timeout = consts.api.defaults.auth.timeout;

instance.interceptors.request.use(
    (config) => {
        //const token = this.$cookies.get('token')
        //const token = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.authtoken');
        const token = store.state.security.tokens.access_token
        if (token) {
            config.headers['Authorization'] = store.getters.getHeaderAuthorization || `Bearer ${token}`;
        }
        config.headers['X-Accept-Language'] = i18n.global.locale || 'en'
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // if (response.status === 200) {
        //     return response;
        // }
        // return Promise.reject(response);
        return response;
    },
    error => {
        if (error) {
            const status = error?.response ? error.response?.status : -1
            store.commit('addHttpResponseStatus', status)
            if (status === 401) {
                //if(store.state.security.tokens) {
                    store.commit('updateTokens', {})
                //}
                if(!(['/login','/logout','/site/logout']).includes(location.pathname)) {
                    window.location.href = window.location.origin + '/site/logout'
                }
            }
        }
        return Promise.reject(error);
    }
);

export default instance;