<template>
    <div class="spinner-container">
        <div class="loader-spinner">
            <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle class="load one" cx="60" cy="60" r="20" pathLength="1" />
                <circle class="load two" cx="60" cy="60" r="10" />
                <circle class="load three" cx="60" cy="60" r="30" pathLength="1" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "SpinnerIcon"
}
</script>

<style lang="scss" scoped>
$color: rgb(0, 92, 132);

.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-spinner {
    width: 150px;
    height: 150px;

    svg {
        width: 90%;
        fill: none;
    }
}
.load {
    transform-origin: 50% 50%;
    stroke-dasharray: 0.7 0.3;
    stroke-linecap: round;
    stroke-width: 3px;
    stroke: $color;

    &.one {
        animation: load 1.5s infinite ease-in;
        animation-direction: reverse;
    }
    &.two {
        fill: $color;
    }
    &.three {
        animation: load 1.5s infinite;
    }
    .loading-page__spinner .load {
        stroke: var(--color-font-light);
        &.two {
            fill: var(--color-font-light);
        }
    }
}

@keyframes load {
    100% {
        transform: rotate(360deg);
    }
}
</style>