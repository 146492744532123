<template>
    <div class="list__item item item_view_head list-units-scroller-group-height"
        :class="{'item_type_collapses-group': true, 'item_status_collapsed': false, 'item_active': showActive == true}"
        @click.stop="toggleActiveGroup"
    >
        <h4 class="list__title"> {{ name }} </h4>
    </div>
</template>
<script>
    import {
        mapActions,
        // mapMutations,
        // mapGetters,
    } from "vuex";

    export default {
        name: 'UnitsList_group',
        components: {},
        props: {
            'title': String,
            'count': [String, Number],
            'clickable': {
                type: Boolean,
                default: () => { return false }
            }
        },
        data() {
            return {
                showActive: false,
            }
        },
        computed: {
            // ...mapGetters([
            // ]),
            name(){
                return this.title + ' ' +  (this.count ? '('+this.count+')' : '')
            }
        },
        methods: {
            ...mapActions([
                "setGroupShowUnits",
            ]),
            toggleActiveGroup(){
                if(!this.clickable) return false
                this.showActive = !this.showActive
            }
        },
        updated() {
            //console.log(`group ${this.groupId} updated`);
        }
    }
</script>