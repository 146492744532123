const RESOURCE_NAME = '/maintenances';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),

    //maintenance-helper/units
    getUnits(params) {
        return axios.get(`/maintenance/units`, {params});
    },
    //maintenance-helper/units
    getTypesOfWork(params) {
        return axios.get(`/maintenance/types-of-work`, {params});
    },
});