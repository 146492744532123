// import Vue from 'vue'
import consts from "@/consts"
import {getApiProps} from "@/lib/lib";
// import range from "lodash/range";
// import {objectComparison} from "@/lib/lib";
const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByHwTitle = function (a, b) {
    let cmp = collator.compare(a?.hw__title || '', b?.hw__title || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        unitsHwTypesFullLoad: false,
        unitsHwTypes: [],
    },
    actions: {
        fetchUnitsHwTypes/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsHwTypes', time: Date.now() / 1000})

                const params = getApiProps('hw_types', args)
                this.$api.unitshwtypes.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsHwTypes', inprogress: false})
                    });
            })
        },
        fetchUnitsHwTypesAll({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchUnitsHwTypesAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    dispatch('fetchUnitsHwTypes', {lite: true})
                        .then((data) => {
                            commit('setUnitsHwTypes', data)
                            commit('setUnitsHwTypesFullLoad', true)
                            resolve(data.length)
                        })
                        .catch((error) => {
                            reject(error)
                            console.error(error);
                            setTimeout(() => {
                                dispatch('fetchUnitsHwTypesAll', {})
                            }, 60 * 1000)
                        })
                })
                .finally(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsHwTypesAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', inprogress: false})
                });
        },
        fetchUnitsHwTypesChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isUnitsHwTypesFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUnitsHwTypes', args)
                .then((data) => {
                    commit('updateUnitsHwTypes', data)
                    return dispatch('fetchUnitsHwTypes', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterUnitsHwTypes', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsHwTypesChanged', inprogress: false})
                });
        },
        saveUnitsHwType({dispatch}, unitsHwType) {
            let fn = (unitsHwType.id) ? 'updateUnitsHwType' : 'createUnitsHwType'
            return dispatch(fn, unitsHwType);
        },
        createUnitsHwType({commit, dispatch}, unitsHwType) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('hwtype')
                this.$api.unitshwtypes.create(unitsHwType, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsHwType', response.data)
                            dispatch('fetchUnitsHwTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUnitsHwType({commit, dispatch}, unitsHwType) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('hwtype')
                this.$api.unitshwtypes.update(unitsHwType.id, unitsHwType, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsHwType', response.data)
                            dispatch('fetchUnitsHwTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUnitsHwType({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                this.$api.unitshwtypes.delete(id)
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteUnitsHwType', id)
                            dispatch('fetchUnitsHwTypesChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setUnitsHwTypesFullLoad(state, FullLoad) {
            state.unitsHwTypesFullLoad = state.unitsHwTypesFullLoad || FullLoad
        },
        setUnitsHwTypes(state, nUnitsHwTypes) {
            nUnitsHwTypes = nUnitsHwTypes.map(hwt => {
                if (hwt?.hw_title) hwt.hw__title = hwt.hw_title.toLocaleLowerCase()
                return Object.freeze(hwt)
            })
            nUnitsHwTypes.sort(sortByHwTitle)
            state.unitsHwTypes = nUnitsHwTypes
        },
        updateUnitsHwTypes(state, nUnitsHwTypes) {
            if (!state.unitsHwTypes.length) {
                nUnitsHwTypes = nUnitsHwTypes.map(hwt => {
                    if (hwt?.hw_title) hwt.hw__title = hwt.hw_title.toLocaleLowerCase()
                    return Object.freeze(hwt)
                })
                nUnitsHwTypes.sort(sortByHwTitle)
                state.unitsHwTypes = nUnitsHwTypes
                return true
            }

            nUnitsHwTypes.forEach(function (nUnitsHwType) {
                let i = state.unitsHwTypes.findIndex(hwt => (hwt.id == nUnitsHwType.id))
                if (i < 0) {
                    state.unitsHwTypes.push(Object.freeze(nUnitsHwType))
                } else
                if (!state.unitsHwTypesFullLoad || state.unitsHwTypes[i][changedField] != nUnitsHwType[changedField]) {
                    // updateObjectByDiff(state.unitsHwTypes[i], nUnitsHwType)
                    delete nUnitsHwType.id
                    state.unitsHwTypes[i] = Object.freeze({...state.unitsHwTypes[i], ...nUnitsHwType})
                }
            })
        },
        filterUnitsHwTypes(state, nUnitsHwTypes) {
            let nIds = nUnitsHwTypes.map(np => np.id)
            let removedIds = state.unitsHwTypes.filter(hwt => !nIds.includes(hwt.id)).map(hwt => hwt.id)
            removedIds.forEach(removedId => {
                let i = state.unitsHwTypes.findIndex(hwt => (hwt.id == removedId))
                if (i != -1) {
                    state.unitsHwTypes.splice(i, 1)
                }
            })
        },
        updateUnitsHwType(state, nUnitsHwType) {
            let i = state.unitsHwTypes.findIndex(hwt => (hwt.id == nUnitsHwType.id))
            if (i < 0) {
                state.unitsHwTypes.push(Object.freeze(nUnitsHwType))
            } else
            if (!state.unitsHwTypesFullLoad || state.unitsHwTypes[i][changedField] != nUnitsHwType[changedField]) {
                // updateObjectByDiff(state.unitsHwTypes[i], nUnitsHwType)
                delete nUnitsHwType.id
                state.unitsHwTypes[i] = Object.freeze({...state.unitsHwTypes[i], ...nUnitsHwType})
            }
        },
        deleteUnitsHwType(state, id) {
            let i = state.unitsHwTypes.findIndex(t => (t.id == id))
            if (i != -1) {
                state.unitsHwTypes.splice(i, 1)
            }
        },
        clearUnitsHwTypes(state) {
            state.unitsHwTypes = [];
            state.unitsHwTypesFullLoad = false
        },
    },
    getters: {
        isUnitsHwTypesFullLoad(state) {
            return state.unitsHwTypesFullLoad
        },
        getUnitsHwTypes(state) {
            return state.unitsHwTypes
        },
        getUnitsHwTypesByType(state) {
            return state.unitsHwTypes.reduce((types, ut) => {
                types[ut.hw_type] = ut.hw_title || ut.hw_type
                return types
            }, {})
        },
        getHwTypesByType(state) {
            return state.unitsHwTypes.reduce((types, ut) => {
                types[ut.hw_type] = {...ut}
                delete types[ut.hw_type].hw_type
                return types
            }, {})
        }

    }
}