// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    {
        path: '/objs-changes',
        name: consts.routerNames.object_changes.main,
        meta: {
            mainRouteName: consts.routerNames.object_changes.main,
            bodyGrid: "1X1",
        },
        component: () => import('@/views/intuit/admin/ObjectsChangesPage.vue')
    },
];

export default routes;