const RESOURCE_NAME = '/users';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    remove(id, params) {
        return axios.delete(`${RESOURCE_NAME}/${id}`, {params});
    },
    restore(id, params) {
        return axios.put(`${RESOURCE_NAME}/${id}/restore`, {}, {params});
    },
    deletePermanently(id, params) {
        return axios.delete(`${RESOURCE_NAME}/${id}?permanently`, {params});
    },
    keys(id, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/keys`, {params});
    },
    backgrounds(id, params) {
        return axios.get(`${RESOURCE_NAME}/${id}/backgrounds`, {params});
    },
});