<template>
    <UnitInfo_indicator_ignition :unitId="unitId"/>
</template>
<script>
    import UnitInfo_indicator_ignition from "@/components/units/info/UnitInfo_indicator_ignition";

    export default {
        name: 'UnitsList_unit_ignition_status',
        props: [
            'unitId',
        ],
        components:{
            UnitInfo_indicator_ignition
        },
    }
</script>