import i18n from "@/i18n";

export {
    copyUnitAddress2Clipboard,
}
function copyUnitAddress2Clipboard(lmsg) {
    let data = [];
    let address = lmsg?.address
    let latlng = Object.values((lmsg?.latlng || {})).join(',')
    if(address) {
        data.push(i18n.global.t('text.Address') + ':\n' + address)
    }
    if(latlng) {
        data.push(i18n.global.t('text.Coordinates') + ':\n' + latlng)
        data.push(i18n.global.t('text.Google Link') + ':\n' + 'https://www.google.com/maps/search/?api=1&query=' + latlng)
    }
    data = data.filter(v => v).join('\n')
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(data)
    } else
    if (window.clipboardData) {
        window.clipboardData.setData("Text", data)
        return Promise.resolve(true)
    }
    return Promise.reject(null)
}