<template>
    <div class="field-type-upload-img">
        <localization :language="$i18n.locale">
            <intl :locale="'bg'" >
                <kendo-upload
                    :list="customListItemUI"
                    :files="files"
                    :batch="false"
                    :multiple="true"
                    :auto-upload="true"
                    :with-credentials="false"
                    :restrictions="{
                        allowedExtensions,
                        minFileSize: 10,//in bytes
                        maxFileSize: 4000000,//in bytes ~4M
                    }"
                    :actions-layout="actionsLayout"

                    :save-headers="{'Authorization': 'Bearer '+token}"
                    :save-url="uploadUrl+'save'"
                    :remove-headers="{'Authorization': 'Bearer '+token}"
                    :remove-url="uploadUrl+'remove'"

                    @add="onAdd"
                    @progress="onProgress"
                    @statuschange="onStatusChange"
                >
                    <!--@beforeupload="onBeforeUpload"-->
                    <!--@beforeremove="onBeforeRemove"-->
                    <template v-slot:myTemplate="{props}">
                        <div class="field-type-upload-img__item"  v-if="!props.files[0]?.validationErrors">
                            <div class="field-type-upload-img__item-img"
                                 :class="{'field-type-upload-img__item-img-error': props.files[0]?.validationErrors}"
                                 :title="props.files[0]?.name"
                            >
                                <template v-if="props.files[0].progress === 100">
                                    <img :src="filePreviews[props.files[0]?.uid]" :alt="props.files[0]?.name" v-if="!props.files[0]?.validationErrors">
                                    <div class="field-type-upload-img__item-img-error__container" v-else>
                                        <SvgIcon name="error-image-photo-icon" class="icon"/>
                                        <span>{{props.files[0]?.name}}</span>
                                    </div>
                                </template>
                                <SectionLoading v-else/>
                            </div>
                            <button class="field-type-upload-img__button" @click="onRemove(props.files[0]?.uid)">
                                <SvgIcon name="common__close"/>
                            </button>
                        </div>
                    </template>
                </kendo-upload>
            </intl>
        </localization>
    </div>
</template>
<script>
//https://www.telerik.com/kendo-vue-ui/components/upload/#toc-events
//https://www.telerik.com/kendo-vue-ui/components/upload/custom-rendering/
import {Upload} from '@progress/kendo-vue-upload';
import SvgIcon from "@/components/_base/SvgIcon.vue";
import {useToast} from "vue-toastification";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-vue-intl';
import en from "@/lang/upload/en.json";
import es from "@/lang/upload/es.json";
import pt from "@/lang/upload/pt.json";
import ar from "@/lang/upload/ar.json";
import consts from "@/consts";

const localizationUpload = {
    en,
    es,
    pt,
    ar
};
let languages = consts.langs.map(lng => lng.id)
languages.forEach(lng => {
    loadMessages(localizationUpload[lng], lng);
})

export default {
    name: 'FieldGroup_upload_multiple_img',
    emits:['update:modelValue', 'errors'],
    props: {
        allowedExtensions:{
            type: Array,
            default: () => [ '.jpg', '.jpeg', '.png', '.svg'],
        },
        label: String,
        previewStyle: [String, Object, Array],
        modelValue: [Array],
        uploadUrl: String,
        actionsLayout: {
            type: String,
            default: 'stretched', //'start','end','center'
        }
    },
    components: {
        SvgIcon,
        'kendo-upload': Upload,
        intl: IntlProvider,
        localization: LocalizationProvider
    },
    data() {
        return {
            addOneValidFile: false,
            success: false,
            customListItemUI: 'myTemplate',
            filePreviews: {},
            index: 0,
            toast: useToast()
        }
    },
    computed: {
        token() {
            return localStorage.getItem(process.env.VUE_APP_PRODUCT + '.authtoken')
        },
        files: {
            get() {
                return (this.modelValue || []).map((bg, i) => {
                    if (typeof bg === 'string') {
                        let uid = '#' + i;
                        this.filePreviews[uid] = bg;
                        bg = this.parseUrlToUploadObject(bg, uid)
                    }
                    return bg
                })
            },
            set(v) {
                this.$emit('update:modelValue', v)
            }
        },
    },
    watch:{
        // modelValue: {
        //     handler: function (nVal){
        //         this.$emit('update:modelValue', nVal?.map((val, i) => {
        //                 if (typeof val === 'string') {
        //                     let uid = '#' + i;
        //                     this.filePreviews[uid] = val;
        //                     val = this.parseUrlToUploadObject(val, uid)
        //                     return val
        //                 }
        //                 return val
        //             })
        //         )
        //     },
        //     immediate: true,
        // }
    },
    methods: {
        parseUrlToUploadObject(url, uid) {
            let i = url.indexOf('?'); if(i >= 0) url = url.substring(0,i)
            let name = url.substring(url.lastIndexOf('/') + 1);
            let extension = url.substring(url.lastIndexOf('.'))
            return {uid, extension, name, url, progress: 100, status: 4, exists: true}
        },
        afterStateChange(event) {
            event.affectedFiles
                .filter(file => !file.validationErrors)
                .forEach(file => {
                    const reader = new FileReader();
                    reader.onloadend = (ev) => {
                        this.filePreviews[file.uid] = ev.target.result;
                    };
                    reader.readAsDataURL(file.getRawFile());
                });
        },
        onAdd(event) {
            // console.log('onAdd', event)
            let affectedFiles = event.affectedFiles
            let affectedFilesNames = affectedFiles.map(af => af.name)
            let affectedFilesUid = affectedFiles.map(af => af.uid)
            this.files = event.newState
                .filter(file => (!affectedFilesNames.includes(file.name) || affectedFilesUid.includes(file.uid)))

            let errors = affectedFiles
                .filter(file => file.validationErrors)
                .map(file => {
                    return file.validationErrors.map(error => (file.name + ': ' + this.$t('Upload.errors.' + this.$consts.uploadError[error])))
                })
            .flat()

            this.$emit('errors', errors)//!!! ToDo not working ???
            errors.forEach(error => this.toast.error(error))

            this.afterStateChange(event);
        },
        onRemove(uid) {
            // console.log('onRemove', uid)
            delete this.filePreviews[uid]
            this.files = this.files.filter(file => file.uid != uid);
        },
        onProgress(event) {
            // console.log('onProgress', event.newState)
            // this.files = this.files.map(file => event.newState.find(f => f.uid === file.uid));
            this.files = event.newState.filter(e => !e.validationErrors);
        },
        onStatusChange(event) {
            // console.log('onStatusChange', event.newState)
            // this.files = this.files.map(file => event.newState.find(f => f.uid === file.uid));
            this.files = event.newState.filter(e => !e.validationErrors);
        },
        // onBeforeUpload(event) {
        //     //console.log('onBeforeUpload',event)
        //     //event.additionalData.description = this.index++;
        //     event.files[0].name = ''+(this.index++)+'.'+event.files[0].name
        // },
        // onBeforeRemove (event) {
        //     // console.log('onBeforeRemove', event)
        //     event.additionalData.description = 'File remove';
        // }
        // hasOneValidFile() {
        //     return this.files.some((f) => f.status === 4);
        // },
        // handleSubmit(event) {
        //     event.preventDefault();
        //     this.addOneValidFile = !this.hasOneValidFile();
        //     setTimeout(() => {
        //         this.addOneValidFile = false;
        //     }, 3000);
        //
        //     if (!this.addOneValidFile) {
        //         this.success = this.hasOneValidFile();
        //         setTimeout(() => {
        //             this.success = false;
        //         }, 3000);
        //     }
        // },
    },
    created() {
        // console.log('FieldGroup_upload created')
    },
    updated() {
        // console.log('FieldGroup_upload updated')
    },
    mounted() {
        // console.log('FieldGroup_upload mounted')
    }
}
</script>
<style lang="scss">
.field-type-upload-img{
    width: 100%;
    background: white;
    .k-widget.k-upload .k-upload-files {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        gap: 10px;
        max-height: none;
        min-height: 150px;
    }
    .k-upload .k-upload-files .k-file{
        border: none;
        //width: 32%;
        //max-width: 32%;
        //min-width: 220px;
        //min-height: 166px;
        padding: 0;
        position: relative;
    }
    &__item{
        width: 218px;
        height: 100%;
        //min-width: 220px;
        min-height: 166px;
    }
    &__item-img{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #bfcede;
        border-radius: 13px;
        overflow: hidden;
        img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            //height: 100%;
            //object-fit: cover;
        }
    }
    &__item-img-error {
        background: var(--color-primary-25);
        &__container {
            color: var(--color-font-light);
            width: 100%;
            height: 100%;
            border-radius: 13px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .svg-icon {
                width: 60px;
                height: 60px;
            }
        }
    }
    &__errors {
        padding: 10px;
        @include font(13px, 16px, 400, none, normal, red);
        display: flex;
    }
    &__errors-list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }
    button {
        position: absolute;
        top: -5px;
        right: -10px;
        background: none;
        border: none;
        color: red;
        background: #bfcede;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
.field-type-upload-img{
    & {
        ::v-deep(.k-upload-files .k-reset) {
            display: flex;
        }
    }
}
</style>