<template>
    <section class="section section_type_admin-table is_new-design">
        <section class="section__head head is_new-design" v-if="showHeaderWrap">
            <div class="section__head-item head__horizontal-line horizontal-line">
                <div class="section__head-item filter-search filter-search_theme_neutral filter-search_width_280">
                    <input class="filter-search__input" type="text" :placeholder="$t('text.Search')" v-model.trim="searchField">
                    <button class="filter-search__close button button_view_icon button_theme_primary">
                        <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close_sm" /></span> </span>
                    </button>
                </div>
                <SectionBalance v-if="showBalance"/>
            </div>
            <div class="section__head-item head__horizontal-line horizontal-line horizontal-line__align-center">
                <div class="head__logo logo">
                    <img :src="(getAppUser.head__logo || {}).url || require('@/assets/img/empty1px.png')">
                </div>
                <h1 class="head__logo-text logo-text ml__16 pl__16">{{ $t('Admin Panel') }}</h1>
            </div>
            <div class="section__head-item head__horizontal-line horizontal-line horizontal-line_width_280 horizontal-line_align_right">
                <slot name="toolbarCommands"/>
            </div>
        </section>
        <slot/>
    </section>
</template>

<script>
import SectionBalance from "@/components/app/SectionBalance.vue";
import {mapGetters} from "vuex";
export default {
    name: "AdminWrapPage",
    emits:['update:search'],
    props: {
        search: String,
        showHeaderWrap: {
            type: Boolean,
            default: () => true
        },
        showBalance: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
        }
    },
    components:{
        SectionBalance
    },
    computed:{
        ...mapGetters([
            "getAppUser",
        ]),
        searchField:{
            get(){ return this.search || '' },
            set(str){ this.$emit('update:search', str) }
        }
    },
    methods:{
    }
}
</script>

<style lang="scss">
.section {
    &.section_type_admin-table {
        display: grid;
        grid-template-rows: auto 1fr;

        .section__head {
            .filter-search {
                max-width: 12rem;
                margin-right: 10px;
            }
        }

        .section__body {
            overflow: hidden;
            padding: .75rem;
        }
        .section__head-item{
            flex: 1;
            &:first-child{
                max-width: 35%;
            }
            .icon {
                max-width: 100%;
            }
        }
    }
}
.prometheus {
    .section {
        &.section_type_admin-table {
            grid-template-rows: 1fr;
        }
    }
}

</style>