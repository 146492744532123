const RESOURCE_NAME = '/video-requests';

export default axios => ({
    create(unitId, data, params) {
        if (!(unitId > '')) {
            return axios.post(`${RESOURCE_NAME}`, data, {params});
        }
        return axios.post(`/unit/${unitId}${RESOURCE_NAME}`, data, {params});
    },
    getLast(last, params) {
        //?last/(n (sec|second|min|minute|hour|day|month)(s?))
        return axios.get(`${RESOURCE_NAME}/last/${last}`, {params});
    },
    get4Date(unitId, date, params) {
        if (!(unitId > '')) {
            //?date=y-m-d
            return axios.get(`${RESOURCE_NAME}?date=${date}`, {params});
        }
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/${date}`, {params});
    },
    get4Period(unitId, from, to, params) {
        if (!(unitId > '')) {
            //?start-time=int&end-time=int
            return axios.get(`${RESOURCE_NAME}?start-time=${from}&end-time=${to}`, {params});
        }
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/${from}/${to}`, {params});
    },
    getStatistics(unitId, params) {
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/statistics`, {params});
    },
});