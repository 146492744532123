<template>
    <FieldGroup__base_wrap
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
        :warning="warning"
        :disabled="disabled"
    >
        <div class="field-group__input-container">
            <input type="text"
                   tabindex="1"
                   class="input_hidden-password"
                   style="position: absolute; top: -2000px"
                   :disabled="disabled"
                   @focus="onFocus"
                   v-if="optional_hidden_field"
            >

            <input class="field-group__input"
                   :readonly="readOnly"
                   :autocomplete="false"
                   :class="fieldGroup_input_class"
                   :disabled="disabled===true"
                   :type="type"
                   :id="id"
                   :name="id"
                   :placeholder="placeholder_text"
                   :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                   @focus="readOnly = false"
                   @blur="readOnly = true"
            >
            <div class="field-group__show-value" v-if="filterCommands.length">
                <template v-for="command in filterCommands" :key="command.id">
                    <button class="button button_command" @click.stop="command.fn()">
                        <SvgIcon :name="command.icon()"/>
                    </button>
                </template>
            </div>
        </div>
    </FieldGroup__base_wrap>
</template>
<script>
    export default {
        name: 'FieldGroup_password',
        emits:['update:modelValue'],
        props: {
            'fieldGroup_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type:[String, Array, Object],
                default: () => ''
            },
            'optional_hidden_field':{
              type: Boolean,
              default: () => false
            },
            'id':String,
            'label':String,
            'modelValue':[Number, String, Array, Object],
            'placeholder':String,
            'error':{
                type:[String, Array, Object],
                default: () => ''
            },
            'warning': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info':String,
            'input_type': {
                type: String,
                default: () => 'password'
            },
            'input_icon':String,
            'disabled':Boolean,
            'commands': {
                type: Array,
                default: () => ['showPassword'],
            },
        },
        data() {
            return {
                readOnly: true,
                showValuePassword: false,
                defCommands: [
                    {
                        id: 'showPassword',
                        icon: () => this.showValuePassword ? 'circle__hidden' : 'circle__shown',
                        fn: () => this.showValuePassword = !this.showValuePassword,
                        view: this.input_type === 'password'
                    },
                    {
                        id: 'clear',
                        icon: () => 'common__close',
                        fn: () => this.$emit('update:modelValue', ''),
                        view: true
                    },
                ]
            }
        },
        computed: {
            filterCommands() {
                return !this.disabled ? this.defCommands.filter(command => this.commands.includes(command.id) && command.view) : []
            },
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter')+' '+ this.label.toLowerCase()
            },
            type() {
                let type = this.input_type ? this.input_type : 'password'
                if(type === 'password' && this.showValuePassword) {
                    type = 'text'
                }
                return type
            }
        },
        methods: {
            onFocus(e){
                e.target.nextElementSibling.focus()
            },
        },
        mounted() {
            //this.changeReadOnly()
        },
        updated() {
            //console.log('FieldGroup_input updated', this.id, this.error)
        },
    }
</script>
