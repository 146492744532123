<template>
    <UnitInfo_indicator_connection :unitId="unitId"/>
</template>
<script>
    import UnitInfo_indicator_connection from "@/components/units/info/UnitInfo_indicator_connection";

    export default {
        name: 'UnitsList_unit_connection_status',
        props: [
            'unitId',
        ],
        components:{
            UnitInfo_indicator_connection
        },
    }
</script>