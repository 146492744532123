import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import consts from '@/consts'

let routes = []
import routes_def from './default.js'
routes.push(...routes_def)

//intuit
import routes_intuit_portal from './intuit.portal.js'
routes.push(...routes_intuit_portal)
import routes_intuit_admin from './intuit.admin.js'
routes.push(...routes_intuit_admin)

//prometheus
import routes_prometheus_portal from './prometheus.portal.js'
routes.push(...routes_prometheus_portal)
import routes_prometheus_admin from './prometheus.admin.js'
routes.push(...routes_prometheus_admin)

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from) => {
    // console.log('beforeEach', to.name/*, from?.name*/)
    // store.commit('setShowFilterDropdownMenu', false)
    let isLimited = store.getters.isLimitedUser
    let isGuest = store.getters.isGuest
    if (isGuest && !to.meta.isGuest) {
        return {name: 'Login'}
    } else
    if(isLimited && !to.meta?.isLimited) {
        return  false;
    } else
    if (to.name === 'Login' && !isGuest) {
        return consts.default_route
    } else
    if (to.name === 'null' && to.path !== consts.default_route) {
        return consts.default_route
    } else
    if (!from.name && to.name.split('.')[1]) {
        //ToDo fix ???
        return consts.default_route
    }
    if(!from.name && !to.redirectedFrom && to.name != 'Units'){
        return {name: to.name, query: to.query}
    }
    if(to.name == consts.routerNames.units.type.xpower) {
        return {...to, name: consts.routerNames.units.type.xpower + '.control'}
    }
    if(to.name == consts.routerNames.tracks.main && !to.params.date) {
        return {
            ...to,
            params: {
                ...to.params,
                date: store.getters.getDateToday,
            }
        }
    }
    if(to.name == consts.routerNames.notificationsevents.main && !to.params.date) {
        return {
            ...to,
            params: {
                ...to.params,
                date: store.getters.getDateToday,
            }
        }
    }
    // explicitly return false to cancel the navigation
    // return false
});
router.afterEach(async (to, from, failure) => {
    if(!failure) {
        // if (to?.meta?.mainRouteName !== from?.meta?.mainRouteName) {
        //     store.dispatch('setShowMainList', !!to?.meta?.list)
        // }
    }
});

export default router
