<template>
    <h5 class="list__address">{{address}}</h5>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_address',
        props: [
            'unitId'
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsLmsgsByIds",
            ]),
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId] || {}
            },
            address(){
                return this.unitLmsg?.address || ''
            },
        },
        updated: function() {
           // console.log(`UnitsList_unit_address ${this.address} updated`);
        },
    }
</script>