<template>
    <div class="field-group" :class="fieldGroup__class">
        <div class="field-group__img-wrap" :style="styleContainer">
            <label class="field-group__label" :class="fieldGroup_label_class" :for="id">
                {{ label }}
            </label>

            <ul class="field-group__list-img">
                <li v-for="image in list"
                    class="field-group__img-item"
                    :class="{'field-group__img-item--active': selected == image[field_id]}"
                    :key="image.id"
                    :style="styleWrapImage"
                    @click="selected = image[field_id]"
                >
                    <img :src="image.url" :alt="image.alt" :title="image.title" :style="styleImage">
                </li>
                <!--       slot         -->
                <slot name="listAdd"></slot>
            </ul>

            <span class="field-group__icon icon" v-if="input_icon">
                <span class="icon__symbol"><SvgIcon class="icon" :name="input_icon"/></span>
            </span>

            <span class="field-group__help" :class="fieldGroup_info_class">
                <ul class="field-group__help__error-list">
                    <template v-for="(warning, i) in warnings" :key="i">
                    <li class="field-group__help__error-list-warning">
                        <SvgIcon class="field-group__help__error-list-warning__icon" name="common__warning"/>
                        {{ warning }}
                    </li>
                </template>
                    <template v-for="(error, i) in errors" :key="i">
                        <li class="">{{ error }}</li>
                    </template>
                </ul>
                <span class="field-group__help__info">{{ info }}</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "FieldGroup_image_list",
    components: {},

    emits:['update:modelValue'],
    props: {
        fieldGroup_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        fieldGroup_label_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        fieldGroup_input_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        fieldGroup_info_class: {
            type: [String, Array, Object],
            default: () => ''
        },
        label: String,
        previewStyle: [String, Object, Array],
        modelValue: [String, Object, Array],
        error: {
            type: [String, Array, Object],
            default: () => ''
        },
        warning: {
            type: [String, Array, Object],
            default: () => ''
        },
        id: [String, Number],
        info: [String, Number],
        input_icon: [String],
        field_id: {
            type: [String],
            default: () => 'id'
        },
        field_title: {
            type: [String],
            default: () => 'title'
        },
        field_url: {
            type: [String],
            default: () => 'url'
        },
        field_alt: {
            type: [String],
            default: () => 'alt'
        },
        items: [Array, Object],
        sortBy: {
            type: [String, Function],
            default: () => 'title'
        },
        disableSortBy: [Boolean],
        styleImage: [String, Array, Object],
        styleContainer: [String, Array, Object],
        styleWrapImage: [String, Array, Object]
    },
    data() {
        return {}
    },
    methods: {
    },
    computed: {
        selected: {
            get(){ return this.modelValue },
            set(v){ this.$emit('update:modelValue', v) }
        },
        fieldGroup__class() {
            let fieldGroup_class = this.fieldGroup_class
            let type = (typeof this.fieldGroup_class)
            if (type === 'string') {
                return fieldGroup_class
                    + ((this.error > '') ? ' has-error ' : '')
                    + ((this.warning > '') ? ' has-warning ' : '')
                    + ((this.disabled === true) ? ' is_disabled ' : '')
            } else
            if (type === 'object') {
                if (Array.isArray(this.fieldGroup_class)) {
                    if (this.error > '') fieldGroup_class.push('has-error')
                    if (this.warning) fieldGroup_class.push('has-warning')
                    if (this.disabled === true) fieldGroup_class.push('is_disabled')
                    return fieldGroup_class
                } else {
                    return {
                        'is_disabled': (this.disabled === true),
                        'has-error': (this.error > ''),
                        'has-warning': (this.warning > ''),
                        ...fieldGroup_class
                    }
                }
            }
            return this.fieldGroup_class
        },
        list() {
            let field_id = this.field_id
            let field_title = this.field_title
            let field_url = this.field_url
            let field_alt = this.field_alt
            let field_sortBy = (typeof this.sortBy === 'string') ? this.sortBy : ''
            let addFieldSortBy = (!this.disableSortBy && (typeof this.sortBy === 'string'))
            let list = []
            if (Array.isArray(this.items)) {
                list = this.items.map(item => {
                    let listItem = {id: item[field_id], title: item[field_title], url: item[field_url], alt: item[field_alt]}
                    if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                    return listItem
                })
            } else {
                //console.warn('items isn\'t array', (typeof this.items))
                const objectArray = Object.entries(this.items);
                objectArray.forEach(([id, item]) => {
                    if (typeof item == 'object' && item[field_title] && item[field_url] && item[field_alt]) {
                        let listItem = {id, title: item[field_title], url: item[field_url], alt: item[field_alt]}
                        if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                        list.push(listItem)
                    }
                });
            }
            let sortBy = (typeof this.sortBy !== 'string') ? this.sortBy : 'sortBy'
            if (!this.disableSortBy) {
                if (typeof sortBy === 'string') {
                    let field = '' + sortBy
                    sortBy = (a, b) => {
                        return (a[field] == b[field]) ? 0 :
                            (a[field] > b[field]) ? 1 : -1;
                    }
                }
                if (typeof sortBy === 'function') {
                    list.sort(sortBy);
                } else {
                    console.warn('unknown method sortBy: ', typeof sortBy, sortBy)
                }
            }
            return list
        },
        errors() {
            if (!this.error) return []
            let type = (typeof this.error)
            if (type === 'string') {
                return [this.error]
            } else
            if (type === 'object' && Array.isArray(this.error)) {
                return this.error.reduce( (errors , e) => {
                    if(e.$message > ''){//vuelidate
                        errors.push(e.$message)
                    } else
                    if(e.$response){//vuelidate api
                        if(e.$response.$messages && e.$response.$messages.length)
                            errors.push(...e.$response.$messages)
                    } else
                    if (typeof e === 'string') {// string
                        errors.push(e)
                    } else {
                        console.warn('errors:', e)
                    }
                    return errors
                },[])
            }
            console.error('FieldGroup_input::errors', type, this.error)
            return []
        },
        warnings() {
            if (!this.warning) return []
            let type = (typeof this.warning)
            if (type === 'string') {
                return [this.warning]
            } else
            if (type === 'object' && Array.isArray(this.warning)) {
                return this.warning
            } else {
                let keys = Object.keys(this.warning)
                return  keys.map(k => this.warning[k])
            }
        },
    }
}
</script>

<style scoped>

</style>