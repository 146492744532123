// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a?.fromDateTime?.utc - b.a?.fromDateTime?.utc;
}

export default {
    state: {
        tiveShipmentAlertsFullLoad: false,
        tiveShipmentAlertsLiteLoad: false,
        tiveShipmentAlerts: [],
    },
    actions: {
        fetchTiveShipmentAlerts/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                let id = args.id
                if (!getters.apiToken || !id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveShipmentAlerts', time: Date.now() / 1000})

                const params = getApiProps('tiveShipmentAlerts', args)
                this.$api.tiveshipmentalerts.shipmentAlerts(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveShipmentAlerts', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveShipmentAlertsLiteLoad', true)
                        commit('setTiveShipmentAlertsFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveShipmentAlerts', inprogress: false})
                    });
            })
        },


    },
    mutations: {
        setTiveShipmentAlertsFullLoad(state, FullLoad) {
            state.tiveShipmentAlertsFullLoad = state.tiveShipmentAlertsFullLoad || FullLoad
        },
        setTiveShipmentAlertsLiteLoad(state, LitaLoad) {
            state.tiveShipmentAlertsLiteLoad = state.tiveShipmentAlertsLiteLoad || LitaLoad
        },

        setTiveShipmentAlerts(state, nTiveShipmentAlerts) {
            nTiveShipmentAlerts = nTiveShipmentAlerts.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveShipmentAlerts.sort(sortByName)
            state.tiveShipmentAlerts = nTiveShipmentAlerts
        },

        updateTiveShipmentAlerts(state, nTiveShipmentAlerts) {
            if (!state.tiveShipmentAlerts.length) {
                nTiveShipmentAlerts = nTiveShipmentAlerts.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveShipmentAlerts.sort(sortByName)
                state.tiveShipmentAlerts = nTiveShipmentAlerts
                // const chunks = arraySplitIntoChunks(nTiveShipmentAlerts)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveShipmentAlerts.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveShipmentAlerts.forEach(function (nShipmentAlert) {
                if (nShipmentAlert?.name) nShipmentAlert.name_ = nShipmentAlert.name.toLocaleLowerCase()
                let i = state.tiveShipmentAlerts.findIndex(u => (u.xid == nShipmentAlert.xid))
                if (i < 0) {
                    state.tiveShipmentAlerts.push(nShipmentAlert) //(Object.freeze(nShipmentAlert))
                } else {
                    // if (!state.tiveShipmentAlertsFullLoad || state.tiveShipmentAlerts[i][changedField] != nShipmentAlert[changedField]) {
                    updateObjectByDiff(state.tiveShipmentAlerts[i], nShipmentAlert)
                    // delete nShipmentAlert.xid
                    // nShipmentAlert = {...state.tiveShipmentAlerts[i], ...nShipmentAlert}
                    // state.tiveShipmentAlerts[i] = nShipmentAlert //Object.freeze(nShipmentAlert)
                }
            })

        },
        filterTiveShipmentAlerts(state, nTiveShipmentAlerts) {
            // let Ids = state.tiveShipmentAlerts.map(u=> u.xid)
            let nIds = nTiveShipmentAlerts.map(u => u.xid)
            let removedIds = state.tiveShipmentAlerts.filter(u => !nIds.includes(u.xid)).map(u => u.xid)
            removedIds.forEach(removedId => {
                let i = state.tiveShipmentAlerts.findIndex(u => (u.xid == removedId))
                if (i != -1) {
                    state.tiveShipmentAlerts.splice(i, 1)
                }
            })
        },
        updateTiveShipmentAlert(state, nShipmentAlert) {
            if (nShipmentAlert?.name) nShipmentAlert.name_ = nShipmentAlert.name.toLocaleLowerCase()
            let i = state.tiveShipmentAlerts.findIndex(u => (u.xid == nShipmentAlert.xid))
            if (i < 0) {
                state.tiveShipmentAlerts.push(nShipmentAlert) //(Object.freeze(nShipmentAlert))
            } else {
                // if (state.tiveShipmentAlertsFullLoad || state.tiveShipmentAlerts[i][changedField] != nShipmentAlert[changedField]) {
                updateObjectByDiff(state.tiveShipmentAlerts[i], nShipmentAlert)
                // delete nShipmentAlert.xid
                // nShipmentAlert = {...state.tiveShipmentAlerts[i], ...nShipmentAlert}
                // state.tiveShipmentAlerts[i] = nShipmentAlert //Object.freeze(nShipmentAlert)
            }
        },

        clearTiveShipmentAlerts(state) {
            state.tiveShipmentAlerts = []
            state.tiveShipmentAlertsFullLoad = false
        },
    },
    getters: {
        isTiveShipmentAlertsFullLoad(state) {
            return state.tiveShipmentAlertsFullLoad
        },
        isTiveShipmentAlertsLiteLoad(state) {
            return state.tiveShipmentAlertsLiteLoad
        },
        getTiveShipmentAlerts(state) {
            return state.tiveShipmentAlerts
        },
    }
}
