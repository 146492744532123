<template>
    <Chart
        ref="chart"
        :pannable="true"
        :transitions="transitions"
    >
        <!--
            :zoomable="true"
            @seriesclick="onSeriesClick"
        -->
        <ChartLegend :position="'bottom'" :visible="showLegend"/>
        <ChartTooltip :render="'defaultTooltipRender'">
            <template v-slot:defaultTooltipRender="{ props }">
                <div>
                    <h2 class="border border__bottom custom-tooltip">
                        {{ props?.point?.series?.name }} {{ tooltipTextBeforeValue }}
                        ({{ props?.point?.value || 0 }})
                    </h2>
                    <ul>
                        <template v-for="(item,i) in tooltipItems(props)" :key="i">
                            <li>
                                <!--                                v-if="i < 10"-->
                                {{ item }}
                            </li>
                        </template>
                        <!--                        <template v-if="tooltipItems(props).length > 10">-->
                        <!--                            <li>...</li>-->
                        <!--                            <li>Сlick on column to see more</li>-->
                        <!--                        </template>-->
                    </ul>
                </div>
            </template>
        </ChartTooltip>
        <ChartCategoryAxis>
            <ChartCategoryAxisItem
                :categories="rows.map(r => r[params.category])"
                :max="categoryAxisMax"
                :max-divisions="categoryAxisMaxDivisions"
                :labels="{
                  rotation: 'auto',
                }"
                :axis-crossing-value="[0, rows.length]"
            >
            </ChartCategoryAxisItem>
        </ChartCategoryAxis>
        <ChartValueAxis>
            <ChartValueAxisItem
                v-for="(axis, i) in valueAxis"
                :key="i"
                :labels="{ visible: true, format: '#' }"
                :name="axis"
            />
        </ChartValueAxis>
        <ChartSeries>
            <ChartSeriesItem
                v-for="(series, i) in params.series"
                :key="i"
                :name="series.label || series.name"
                :data-items="rows.map(r => r[series.name])"
                :color="series.color"
                :type="type"
                :axis="series.axis"
            />
        </ChartSeries>
    </Chart>
    <!--    popup -->
    <!--    <custom-modal v-if="modalWindows?.chart" v-bind="modalWindows?.chart.props">-->
    <!--        <div>-->
    <!--            <p>{{ modalWindows?.chart.text }}</p>-->
    <!--            <br/>-->
    <!--            <p>{{ $t('Period') }}: {{ modalWindows?.chart.period }}</p>-->
    <!--            <br/>-->
    <!--            <div>-->
    <!--                {{ $t('List') }} :-->
    <!--                <ul>-->
    <!--                    <li v-for="(item, i) in modalWindows?.chart.list" :key="i">{{ item }}</li>-->
    <!--                </ul>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </custom-modal>-->
</template>

<script>
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartValueAxis,
    ChartValueAxisItem,
    // ChartValueAxisTitle,
    // ChartCategoryAxisTitle,
    // ChartXAxis,
    // ChartXAxisItem
    // ChartYAxis,
    // ChartYAxisItem,
} from "@progress/kendo-vue-charts";
import "hammerjs";

export default {
    name: "Chart_series",
    inject:['pdfExportParams'],
    props: {
        'title': String,
        'type': String,
        'rows': [Array, Object],
        'params': Object,
        'customize': Object,
        'showLegend': {
            type: Boolean,
            default: () => {
                return true
            }
        },
        'legendPosition': {
            type: String,
            default: () => {
                return 'bottom'
            }
        },
        'tooltipTextBeforeValue': {
            type: String,
            default: () => {
                return ''
            }
        },
        'valueAxis': {
            type: Array,
            default: () => {
                return []
            }
        },
        'categories': [Array, Object],
        'transitions': {
            type: Boolean,
            default: () => false
        }
    },
    components: {
        Chart,
        ChartLegend,
        ChartSeries,
        ChartSeriesItem,
        ChartTooltip,
        ChartCategoryAxis,
        ChartCategoryAxisItem,
        ChartValueAxis,
        ChartValueAxisItem,
        // ChartValueAxisTitle,
        // ChartCategoryAxisTitle
        // ChartXAxis,
        // ChartXAxisItem,
        // ChartYAxis,
        // ChartYAxisItem,
    },
    data() {
        return {
            modalWindows: {},
            categoryAxisMax: new Date(2000, 1, 0),
            categoryAxisMaxDivisions: 10,
        }
    },
    computed: {},
    methods: {
        modalClose() {
            this.modalWindows = {}
        },
        tooltipItems(props) {
            let categoryItem = this.rows.find(r => r.date == props.point.category)
            return categoryItem?.tooltipItemList[(props.point.series.name).toLowerCase()] || []
        },
        onSeriesClick(val) {
            let list = this.tooltipItems(val)
            this.modalWindows = {
                'chart': {
                    name: this.params.title,
                    period: val.point.category,
                    text: `${val.series.name}: ${val.point.value}`,
                    list,
                    props: {
                        title: this.params.title,
                        buttons: [
                            {id: 'ok', fn: () => this.modalClose()},
                        ]
                    },
                }
            }
        },
    },
    mounted() {
        if(this.pdfExportParams) {
            this.pdfExportParams.push(this.$refs.chart)
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-tooltip {
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    padding: inherit;
    margin: inherit;
}
</style>