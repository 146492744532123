<template>
    <section class="section__content-wrapper section__content-wrapper-vertical">
        <section class="section__head head is_new-design">
            <h1 class="head__title ptb__8 mr__16">
                <span class="head__main-title">{{title}}</span>
                <span class="head__sub-title" v-if="components.length > 1">&nbsp;/&nbsp;{{subtitle}}</span>
            </h1>
            <button class="head__button button button_view_icon button_theme_primary" @click="onCancel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close" /></span> </span>
            </button>
        </section>

        <section class="section__body">
            <nav class="section__navigation">
                <SegmentControl_icon
                    :segment-control-class="'mb__12'"
                    :field_id="'component'"
                    :items="components"
                    :items-errors="errors"
                    v-model="component"
                    v-if="components.length > 1"
                />
            </nav>
            <slot></slot>
        </section>

        <section class="section__footer">
            <button class="button button_view_base button_theme_primary mr__8" @click="onAction"> {{action || $t('buttons.Save')}} </button>
            <button class="button button_view_base button_theme_neutral" @click="onCancel"> {{cancel || $t('buttons.Cancel')}} </button>
        </section>
    </section>
</template>

<script>
export default {
    name: "EditSegmentControl_vertical",
    emits:['update:modelValue', 'save', 'cancel'],
    props: {
        'title': String,
        'subtitle': String,
        'components': Array,
        'modelValue': [Number, String],
        'action': String,
        'cancel': String,
    },
    data() {
        return {}
    },
    computed: {
        errors(){
            return this.components.filter(c => !!c.hasErrors).map(c => c.component)
        },
        component: {
            get(){ return this.modelValue },
            set(v){ this.$emit('update:modelValue', v) }
        },
    },
    methods: {
        onAction(){
            this.$emit('save')
        },
        onCancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style scoped>

</style>