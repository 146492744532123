<template>
    <div class="section__head-item balance" ><!-- no v-if -->
        <span class="balance__title" v-if="!isSuperAdmin">{{ title ? title : $t('text.Balance')}}:</span>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "SectionBalance",
    components: {},
    props: {
        title: String,
        isIconView: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            "apiToken",
            "isGuest",
            "isDealer",
            "getAppUser",
            "getUserStorageKey",
            "isSuperAdmin",
        ]),
    },
    created() {
        // console.log('SectionBalance created');
    },
    mounted() {
        // console.log('SectionBalance mounted');
    },
    updated() {
        // console.log('SectionBalance updated');
    },
}
</script>

<style lang="scss" scoped>
.balance{
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 5px;
    width: 100%;
    @include scroll(var(--color-neutral-25), var(--color-neutral-100), .2rem);
    &__list{
        display: flex;
        gap: 5px;
        &:not(:last-child):after{
            content: ',';
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            font-style: normal;
            text-transform: none;
            font-family: "Open Sans", sans-serif;
            color: var(--color-font-light);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__title{
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        font-style: normal;
        text-transform: none;
        margin-right: 5px;
        font-family: "Open Sans", sans-serif;
        color: var(--color-font-light);
    }
    &__item{
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        font-style: normal;
        text-transform: none;
        font-family: "Open Sans", sans-serif;
        color: var(--color-font-light);
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:last-child):after{
            content: ',';
        }
    }
    &__name{
        margin-right: 5px;
        white-space: nowrap;
    }
    &__count {
        white-space: nowrap;
    }
    &__icon-view {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    &__icon-view-icon {
        display: flex;
        align-items: center;
        position: relative;
        width: 50px;
        height: 50px;
        .icon {
            width: 50px;
            height: 50px;
        }
    }
    &__icon-view-icon-logo {
        display: flex;
        align-items: center;
        width: 21px;
        height: 21px;
        background: white;
        border-radius: 100%;
        position: absolute;
        top: -3px;
        left: -3px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__icon-view-text{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 75%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-60%) translateX(-50%);
        color: white;
        font-size: 9px;
        line-height: 10px;
    }
}
</style>