<template>
    <div class="segment-control segment-control_type_slider"
         :class="segmentControlClass"
    >
        <div class="segment-control__list swiper-container">
            <div class="button-prev-slide swiper-button-prev"></div>
            <swiper
                class="segment-control__list"
                v-bind="slideOptions"
            >
                <swiper-slide v-for="(item,i) in list" :key="'segment-control__item-'+i">
                    <div class="segment-control__item item"
                         :class="[segmentControlItemClass, (item.id == selected)?'item_active': '', (item.id == selected)?'slick-slide_active': '']"
                         @click="selected = item.id"
                    >
                        {{ item.name }}
                    </div>
                </swiper-slide>
            </swiper>
            <div class="button-next-slide swiper-button-next"></div>
        </div>

    </div>

</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

export default {
    name: "SegmentControl_slider",
    emits:['update:modelValue'],
    props: {
        'segment-control-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'segment-control-list-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'segment-control-item-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'slickOptions': {
            type: [Object],
            default: () => {
            }
        },
        'items': [Array, Object],
        'field_id': {
            type: [String],
            default: () => 'id'
        },
        'field_name': {
            type: [String],
            default: () => 'name'
        },
        'modelValue': [Number, String],
        'slidesPerView': {
            type: [Number],
            default: () => 1
        },
        'spaceBetween': {
            type: [Number],
            default: () => 5
        },
        'breakpoints': [Object]
    },
    data() {
        return {
            defaultSlideOptions: {
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                navigation:{ nextEl: '.button-next-slide',prevEl: '.button-prev-slide' },
                modules:[Navigation]
            }
        }
    },
    components: {Swiper,SwiperSlide },
    computed: {
        slideOptions(){
            return {...this.defaultSlideOptions, slidesPerView: this.slidesPerView,spaceBetween: this.spaceBetween, breakpoints: this.breakpoints}
        },
        list() {
            let id = this.field_id
            let name = this.field_name
            let list = []
            if (Array.isArray(this.items)) {
                list = this.items.map(item => {
                    return {id: item[id], name: item[name]}
                })
            } else {
                //console.warn('items isn\'t array', (typeof this.items))
                const objectArray = Object.entries(this.items);
                objectArray.forEach(([id, item]) => {
                    let name = item;
                    if (typeof item == 'object' && item['name'] !== undefined) {//.hasOwnProperty
                        name = item['name']
                    }
                    if (typeof name == 'string') {
                        list.push({id, name})
                    } else {
                        console.warn('item isn\'t string', id, item)
                    }
                });
            }
            return list
        },
        selected: {
            get(){ return this.modelValue },
            set(v){ this.$emit('update:modelValue', v) }
        }
    },
    methods: {
    },
    created() {
        // console.log('SegmentControl_slider created')
    },
    updated() {
        // console.log('SegmentControl_slider updated')
    },
    activated() {
        // console.log('SegmentControl_slider activated')
    },
    deactivated() {
    },
    mounted() {
        // console.log('SegmentControl_slider mounted')
    },
    beforeUnmount() {
    },
}
</script>

<style scoped>

</style>