<template>
    <div class="field-type-upload">
        <div>
            <localization :language="$i18n.locale">
                <intl :locale="'bg'" >
                    <kendo-upload
                        class="mySuperClass"
                        :files="files"
                        :batch="false"
                        :multiple="false"
                        :auto-upload="true"
                        :with-credentials="false"
                        :restrictions="{
                            allowedExtensions: [ '.jpg', '.jpeg', '.png', '.gif', '.ico' ],
                            minFileSize: 10,//in bytes
                            maxFileSize: 4000000,//in bytes ~4M
                        }"
                        :actions-layout="actionsLayout"

                        :save-headers="{'Authorization': 'Bearer '+token}"
                        :save-url="uploadUrl+'save'"
                        :remove-headers="{'Authorization': 'Bearer '+token}"
                        :remove-url="uploadUrl+'remove'"

                        @add="onAdd"
                        @remove="onRemove"
                        @progress="onProgress"
                        @statuschange="onStatusChange"

                        :li-st="'customList'"
                    >
        <!--                <template v-slot:customList="{props}">-->
        <!--                    <ul :data-props="JSON.stringify(props)" :data-async="JSON.stringify(props.async)">-->
        <!--                        <li v-for="file in props.files" :key="file.name" > {{file.name}} </li>-->
        <!--                    </ul>-->
        <!--                </template>-->
                    </kendo-upload>
                </intl>
            </localization>
        </div>
        <div class="field-group field-group_type_image mt__8">
            <label class="field-group__label"> {{ label }} </label>
            <div class="field-group__image">
                <img v-for="fileKey in Object.keys(filePreviews)"
                     :src="filePreviews[fileKey]"
                     :key="fileKey"
                     alt='image preview'
                     :style="previewStyle"
                />
            </div>
        </div>
<!-- <br>{{files}} -->
<!-- <br>{{filePreviews}} -->
    </div>
</template>
<script>
//https://www.telerik.com/kendo-vue-ui/components/upload/#toc-events
//https://www.telerik.com/kendo-vue-ui/components/upload/custom-rendering/
import {Upload} from '@progress/kendo-vue-upload';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-vue-intl';
import en from "@/lang/upload/en.json";
import es from "@/lang/upload/es.json";
import pt from "@/lang/upload/pt.json";
import ar from "@/lang/upload/ar.json";
import consts from "@/consts";

const localizationUpload = {
    en,
    es,
    pt,
    ar
};
let languages = consts.langs.map(lng => lng.id)
languages.forEach(lng => {
    loadMessages(localizationUpload[lng], lng);
})
export default {
    name: 'FieldGroup_upload',
    emits:['update:modelValue'],
    props: {
        label: String,
        previewStyle: [String, Object, Array],
        modelValue: [String, Object, Array],
        uploadUrl: String,
        actionsLayout: {
            type: String,
            default: 'stretched', //'start','end','center'
        }
    },
    components: {
        'kendo-upload': Upload,
        intl: IntlProvider,
        localization: LocalizationProvider
    },
    data() {
        return {
            filePreviews: {},
            //actionsLayout: 'stretched', //'start','end','center'
            addOneValidFile: false,
            success: false,
        }
    },
    computed: {
        token() {
            return localStorage.getItem(process.env.VUE_APP_PRODUCT + '.authtoken')
        },
        files: {
            get() {
                //console.log('files: ', this.modelValue)
                //[{ "extension": ".jpg", "name": "volf.jpg", "progress": 100, status: 4, uid: 'default'}]
                //{ name: 'Picture1', extention: '.jpg', progress: 0, status: UploadFileStatus.Initial }
                if (typeof this.modelValue === "string" && this.modelValue > '') {
                    let filename = this.modelValue
                    let i = filename.indexOf('?'); if(i >= 0) filename = filename.substring(0,i)
                    let name = filename.substring(filename.lastIndexOf('/') + 1);
                    let extension = filename.substring(filename.lastIndexOf('.'))
                    return [{extension, name, progress: 100, status: 4, uid: 'default'}]
                } else
                if (this.modelValue && Object.keys(this.modelValue).length) {
                    return [{...this.modelValue}];
                }/*else
                if(this.modelValue && Array.isArray(this.modelValue)) {
                    return [{...this.modelValue}];
                }*/
                return []
            },
            set(v) {
                if (Array.isArray(v) && v.length) { v = v[0] }
                //console.log('files = ', {...v})
                this.$emit('update:modelValue', {...v})
            }
        },
    },
    watch:{
        modelValue: {
            handler: function (nVal, oldVal) {
                if(nVal && !oldVal && typeof nVal === 'string') {
                    this.filePreviews = {'default': this.modelValue}
                }
            },
            immediate: true
        },
    },
    methods: {
        hasOneValidFile() {
            return this.files.some((f) => f.status === 4);
        },
        onAdd(event) {
            // console.log('onAdd', event)
            this.filePreviews = {}
            const afterStateChange = () => {
                event.affectedFiles
                    .filter(file => !file.validationErrors)
                    .forEach(file => {
                        const reader = new FileReader();
                        reader.onloadend = (ev) => {
                            this.filePreviews[file.uid] = ev.target.result;
                        };
                        reader.readAsDataURL(file.getRawFile());
                    });
            };

            this.files = event.newState;
            afterStateChange();
        },
        onRemove(event) {
            console.log('onRemove', event)
            const filePreviews = {
                ...this.filePreviews
            };

            event.affectedFiles.forEach(file => {
                delete filePreviews[file.uid];
            });

            this.files = event.newState;
            this.filePreviews = filePreviews;
        },
        onProgress(event) {
            // console.log('onProgress', event)
            this.files = event.newState;
        },
        onStatusChange(event) {
            // console.log('onStatusChange', event)
            this.files = event.newState;
        },
        handleSubmit(event) {
            // console.log('handleSubmit', event)
            event.preventDefault();
            this.addOneValidFile = !this.hasOneValidFile();
            setTimeout(() => { this.addOneValidFile = false; }, 3000);

            if (!this.addOneValidFile) {
                this.success = this.hasOneValidFile();
                setTimeout(() => { this.success = false; }, 3000);
            }
        }
    },
    created() {
        // console.log('FieldGroup_upload created')
    },
    updated() {
        // console.log('FieldGroup_upload updated')
    },
    mounted() {
        // console.log('FieldGroup_upload mounted')
    }
}
</script>