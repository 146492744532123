const RESOURCE_NAME = '';
import axios from "axios";
const baseURL = 'https://maps.googleapis.com'
const outputFormat = 'json' //xml
const key = 'AIzaSyDGnpjget1aR0tLIM_XH5CleMjXzeXD1sM';
//const goog_key = 'AIzaSyDPkqJnWr_oHIrgV_xj0Sk-2SyyZg0udxk';

export default () => ({
    distanceMatrix(params) {
        // {
        //     destinations: latlngStart.join(','),
        //     origins: latlngEnd.join(','),
        //     units: 'imperial',
        // }
        //doc https://developers.google.com/maps/documentation/distance-matrix/start?hl=ru#json
        return axios.get(`${RESOURCE_NAME}/maps/api/distancematrix/${outputFormat}`, {params :{...params, key}, baseURL});
    },
    search(params) {
        // {
        //     destinations: latlngStart.join(','),
        //     origins: latlngEnd.join(','),
        //     units: 'imperial',
        // }
        //doc https://developers.google.com/maps/documentation/geocoding/start?hl=ru
        return axios.get(`${RESOURCE_NAME}/maps/api/geocode/${outputFormat}`, {params :{...params, key}, baseURL});
    },
    autocomplete(input, params) {
        //doc https://developers.google.com/maps/documentation/geocoding/start?hl=ru
        return axios.get(`${RESOURCE_NAME}/maps/api/place/autocomplete/${outputFormat}`, {params:{...params, input, key, types: 'geocode'}, baseURL});
    },
    placeDetails(params, place_id, fields='formatted_address,name,geometry,address_components') {
        //doc https://developers.google.com/maps/documentation/geocoding/start?hl=ru
        return axios.get(`${RESOURCE_NAME}/maps/api/place/details/${outputFormat}`, {params :{...params, fields, place_id, key}, baseURL});
    },
});