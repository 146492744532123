export default {
    //chart
    "chart-line": "Line",
    "chart-schedule": "Schedule",
    //map circle
    "circle___geozone_circle": "Geozone",
    "circle___geozone_circle_hidden": "Geozone",
    // "circle__hidden": "",
    "circle__a-point": "A Point",
    "circle__b-point": "B point",
    "circle__d-point": "D point",
    "circle__accelerate": "Accelerate",
    "circle__clock": "Clock",
    "circle__coords": "Coordenadas",
    "circle__hourglass": "Hourglass",
    "circle__link": "Link",
    "circle__lost-connection": "Lost connection",
    "circle__low-battery": "Low battery",
    "circle__max-speed": "Max speed",
    "circle__message": "Message",
    "circle__mileage": "Mileage",
    "circle__movement": "Movement",
    "circle__number": "Number",
    "circle__parking": "Parking",
    "circle__pin": "Pin",
    "circle__radius": "Radius",
    "circle__satellit": "Satellite",
    "circle__shock": "Shock",
    "circle__shown": "Shown",
    "circle__signal": "Signal",
    "circle__speed": "Speed",
    "circle__speed_lg": "Speed",
    "circle__steering-wheel": "Steering wheel",
    "circle__stop": "Stop",
    "circle__turn": "Turn",
    "circle__video-event": "Video event",
    //commands
    "command__active": "Activate",
    "command__block": "Blocked",
    "command__delete": "Deleted",
    "command__edit": "Edit",
    "command__follow": "Follow",
    "command__history": "History",
    "command__key_revoke": "Key revoke",
    "command__key_suspend": "Key suspend",
    "command__password": "Edit password",
    "command__refresh": "Refresh",
    "command__restore": "Restore",
    "command__sign_in_admin": "Sign in admin",
    "command__sign_in_portal": "Sign in portal",
    "command__update_key": "Update_key",
    //common//???
    "common__add": "Add",
    "common__add_lg": "Add",
    "common__add_sm": "Add",
    "common__buy": "Buy",
    "common__chat": "Chat",
    "common__checkbox": "Checkbox",
    "common__checkbox_all": "Checkbox all",
    // "common__chevron_down": "",
    // "common__chevron_left": "",
    // "common__chevron_left_double": "",
    // "common__chevron_right": "",
    // "common__chevron_right_double": "",
    "common__clear_lg": "Clear",
    "common__clear_sm": "Clear",
    "common__close": "Close",
    "common__close_sm": "Close",
    "common__collapse": "Collapse",
    "common__command": "Command",
    "common__copy": "Copy",
    "common__copy_sm": "Copy",
    "common__delete": "Deleted",
    "common__delete_lg": "Deleted",
    "common__delete_sm": "Deleted",
    "common__docs": "Docs",
    "common__edit": "Edit",
    "common__edit_lg": "Edit",
    "common__email": "Email",
    "common__exit": "Exit",
    "common__export": "Export",
    "common__export_sm": "Export",
    "common__favorite": "Favorite",
    "common__filter": "Filter",
    "common__filter_on": "Filter",
    "common__follow_sm": "Follow",
    "common__ftp": "FTP",
    "common__geozone_circle": "Geozone",
    "common__history": "History",
    "common__ignition": "Ignition",
    "common__import_export": "Import ore export",
    "common__info": "Info",
    "common__info_link": "Info link",
    "common__location": "Location",
    "common__locator": "Locator",
    "common__menu_sm": "Menu",
    "common__more": "More",
    "common__more_vertical": "More",
    "common__movement": "Movement",
    "common__notice": "Notice",
    "common__notice_disabled": "No notice",
    "common__online_notifications": "Online notifications",
    "common__parking": "Parking",
    "common__password": "Password",
    "common__pin": "Pin",
    "common__pin_disabled": "Pin disabled",
    "common__print_sm": "Print",
    "common__remove_lg": "Remove",
    "common__reports": "Reports",
    "common__resend_sm": "Resend",
    "common__server-connection": "Server connection",
    "common__server-no-connection": "Server not connection",
    "common__settings": "Settings",
    "common__show": "Show",
    "common__show_disabled": "Not show",
    "common__show_partially": "Show partially",
    "common__signal": "Signal",
    "common__signal_lost": "Signal lost",
    "common__slider": "Slider",
    "common__status_favorite": "Status favorite",
    "common__status_not_notify": "Status not notify",
    "common__status_not_show": "Status not show",
    "common__status_notice_close": "Status notice close",
    "common__status_pin": "Status pin",
    "common__status_tracking": "Status tracking",
    "common__support": "Support",
    "common__track": "Track",
    "common__track_day": "Track of day",
    "common__tracking": "Tracking",
    "common__tracking_disabled": "Tracking disabled",
    "common__upgrade": "Upgrade",
    "common__user": "User",
    "common__user_sm": "User",
    "common__video": "Video",
    "common__warning": "Warning",
    "common__zoom": "Zoom",
    //help
    "help__add_car_icon": "Add car",
    "help__geoitems1_icon": "Geoitems",
    "help__geoitems_icon": "Geoitems",
    "help__login_icon": "Login",
    "help__notices_icon": "Notices",
    "help__settings_icon": "Settings",
    "help__track_icon": "Track",
    "help__unit_icon": "Unit",
    //input
    "input__date": "Date",
    // "input__select": "",
    // "input__select-2": "",
    // "input__select-3": "",
    //car_type//???
    "map__car_type_ambulance": "Ambulance",
    "map__car_type_motorbike": "Motorbike",
    "map__car_type_pickup": "Pickup",
    "map__car_type_police": "Police",
    "map__car_type_sedan": "Sedan",
    "map__car_type_taxi": "Taxi",
    "map__car_type_truck": "Truck",
    //menu
    "menu__alarm": "Alarm",
    "menu__alarm_fill": "Alarm",
    "menu__car": "Units",
    "menu__car_fill": "Units",
    "menu__dashboard": "Dashboard",
    "menu__dashboard_fill": "Dashboard",
    "menu__dealer": "Top off",
    "menu__dealer_fill": "Top off",
    "menu__delete": "Recycle",
    "menu__delete_fill": "Recycle",
    "menu__fuel": "Fuel",
    "menu__fuel_fill": "Fuel",
    "menu__fuel_cards": "Fuel cards",
    "menu__fuel_cards_fill": "Fuel cards",
    "menu__gauges": "Gauges",
    "menu__gauges_fill": "Gauges",
    "menu__geoitem_add": "Add geoitem",
    "menu__geoitems": "Geoitems",
    "menu__geoitems_fill": "Geoitems",
    "menu__hamburger": "Menu",
    "menu__help": "Help",
    "menu__help_fill": "Help",
    "menu__history": "History",
    "menu__history_fill": "History",
    "menu__keys": "Keys",
    "menu__keys_fill": "Keys",
    "menu__maintenance": "Maintenance",
    "menu__maintenance_fill": "Maintenance",
    "menu__more": "More",
    "menu__more_fill": "More",
    "menu__notifications": "Notifications",
    "menu__notifications_fill": "Notifications",
    "menu__passengers": "Passengers",
    "menu__passengers_fill": "Passengers",
    "menu__personnel": "Users",
    "menu__personnel_fill": "Users",
    "menu__prometheus": "Prometheus",
    "menu__prometheus_fill": "Prometheus",
    "menu__reports": "Reports",
    "menu__reports_fill": "Reports",
    "menu__routes": "Routes",
    "menu__routes_fill": "Routes",
    "menu__statistic": "Statistic",
    "menu__statistic_fill": "Statistic",
    "menu__tracks": "Tracks",
    "menu__tracks_fill": "Tracks",
    "menu__video": "Video",
    "menu__video_fill": "Video",
    //notifications
    // "notifications__empty": "",
    "notifications__approaching_geopoint": "Approaching geopoint",
    "notifications__approaching_object": "Approaching object",
    "notifications__complex-logic": "Complex logic",
    "notifications__distance_geopoint": "Distance geopoint",
    "notifications__drain": "Drain",
    "notifications__fuel_control": "Fuel",
    "notifications__geozone": "Geozone",
    "notifications__geozone_entrance_control": "Geozone entrance",
    "notifications__geozone_exit_control": "Geozone exit",
    // "notifications__input": "",
    // "notifications__input-": "",
    // "notifications__input_alert": "",
    "notifications__lost-connection": "Lost connection",
    "notifications__lost_connection": "Lost connection",
    "notifications__low-battery": "Low battery",
    "notifications__maintenance": "Maintenance",
    "notifications__max-speed": "Max speed",
    "notifications__movement": "Movement",
    "notifications__overspeeding_gis": "Overspeeding gis",
    "notifications__parameter_control": "Parameter",
    "notifications__parking": "Parking",
    "notifications__refill": "Refill",
    "notifications__sensor_control": "Sensor",
    "notifications__towing": "Towing",
    "notifications__video-event": "Video event",
    //playback
    // "playback__video-event-1": "",
    // "playback__video-event-2": "",
    // "playback__video-event-3": "",
    "playback__video-group": "Video group",
    "playback__warn-event": "Warn event",
    //segment
    // "segment__full_full_full": "",
    // "segment__full_half_half": "",
    //sensors
    "sensors__battery": "Battery",
    "sensors__co2_level": "CO2",
    "sensors__co_level": "CO",
    "sensors__fuel_level": "Fuel level",
    "sensors__humidity": "Humidity",
    "sensors__illumination": "Illumination",
    "sensors__oil_pressure": "Oil pressure",
    "sensors__rpm": "RPM",
    "sensors__temperature": "Temperature",
    "sensors__voltage": "Voltage",
    //unit_dashboard
    "unit_dashboard__address-point": "Address point",
    "unit_dashboard__battery": "Battery",
    "unit_dashboard__charge": "Charge",
    "unit_dashboard__check": "Check",
    "unit_dashboard__check_danger": "Check",
    "unit_dashboard__ignition": "Ignition",
    "unit_dashboard__main_power": "Main power",
    "unit_dashboard__massage": "Massage",
    "unit_dashboard__mileage": "Mileage",
    "unit_dashboard__online": "Online",
    "unit_dashboard__speed": "Speed",
    "unit_dashboard__status": "Status",
    "unit_dashboard__temperature": "Temperature",
    //units
    "units__ignition_bg": "Ignition",
    "units__ignition_symbol": "Ignition",
    "units__moving_bg": "Moving",
    "units__moving_symbol": "Moving",
    "units__parking_bg": "Parking",
    "units__parking_symbol": "Parking",
    //units types
    "common__complex": "Complex",
    "common__complex_lg": "Complex",
    "common__boat": "Boat",
    "common__boat_lg": "Boat",
    "common__bus": "Bus",
    "common__bus_lg": "Bus",
    "common__car": "Car",
    "common__car_lg": "Car",
    "common__dry_tanker": "Dry tanker",
    "common__dry_trailer": "Dry trailer",
    "common__dry_trailer_lg": "Dry trailer",
    "common__engine": "Engine",
    "common__engine_lg": "Engine",
    "common__fuel_tank": "Fuel tank",
    "common__fuel_tank_lg": "Fuel tank",
    "common__fuel_tanker": "Fuel tanker",
    "common__fuel_tanker_lg": "Fuel tanker",
    "common__generator": "Generator",
    "common__generator_lg": "Generator",
    "common__jet_ski": "Jet ski",
    "common__jet_ski_lg": "Jet ski",
    "common__motorcycle": "Motorcycle",
    "common__motorcycle_lg": "Motorcycle",
    "common__plant_machine": "Plant machine",
    "common__plant_machine_lg": "Plant machine",
    "common__pump": "Pump",
    "common__pump_lg": "Pump",
    "common__reefer_gt": "Reefer",
    "common__reefer_gt_lg": "Reefer",
    "common__reefer_trailer": "Reefer trailer",
    "common__reefer_trailer_lg": "Reefer trailer",
    "common__rv": "RV",
    "common__rv_lg": "RV",
    "common__stationary_unit": "Stationary unit",
    "common__stationary_unit_lg": "Stationary unit",
    "common__tank": "Tank",
    "common__tank_lg": "Tank",
    "common__tractor": "Tractor",
    "common__tractor_lg": "Tractor",
    "common__train": "Train",
    "common__train_lg": "Train",
    "common__truck": "Truck",
    "common__truck_lg": "Truck",
    "common__van": "Van",
    "common__van_lg": "Van",
    "common__water_tanker": "Water tanker",
    "common__water_tanker_lg": "Water tanker",
    //unit reefer
    "unit__reefer": "Unit reefer",
    "reefer": "Reefer",
    "reefer-error": "Reefer error",
    // "reefer-info-1": "",
    // "reefer-info-2": "",
    // "reefer-info-3": "",
    // "reefer-info-4": "",
    // "reefer-info-5": "",
    "reefer-wizard": "Wizard",
    "reefer__fuel": "Fuel",
    "reefer__mode": "Mode",
    "reefer__temperatura": "Temperatura",
    "refeer__coil": "Coil",
    "refeer__malanka": "Energy",
    "refeer__wind": "Wind",
    //video-event
    "video-event_acceleration": "Acceleration",
    "video-event_attention": "Attention",
    "video-event_braking": "Braking",
    "video-event_collision-warning": "Collision warning",
    "video-event_danger-maneuver": "Danger maneuver",
    "video-event_drinking-water": "Drinking water",
    "video-event_driver-distraction": "Driver distraction",
    "video-event_drivers-eyes-closed": "Drivers eyes closed",
    "video-event_left-turn": "Left turn",
    "video-event_left-turn-while-accelerating": "Left turn while accelerating",
    "video-event_left-turn-while-decelerating": "Left turn while decelerating",
    "video-event_no-safety-belt": "No safety belt",
    "video-event_other": "Event",
    "video-event_right-turn": "Right turn",
    "video-event_right-turn-while-accelerating": "Right turn while accelerating",
    "video-event_right-turn-while-decelerating": "Right turn while decelerating",
    "video-event_sign-violation": "Sign violation",
    "video-event_smoking": "Smoking",
    "video-event_speeding": "Speeding",
    "video-event_swerve": "Swerve",
    "video-event_talking-on-cell-phone": "Talking on cell phone",
    "video-event_traffic-light-violation": "Traffic light violation",
    "video-event_turn": "Turn",
    "video-event_yawning": "Yawning",
    //video
    "video-full": "Full",
    "video-play": "Play",
    "video-play-popup": "Play popup",
    "video-small": "Small",
    "video-stop": "Stop",
    "video__camera": "Camera",
    //weather
    "weather__cloudy": "Cloudy",
    "weather__cloudy-2": "Cloudy",
    "weather__day": "Day",
    "weather__day-cloudy-1": "Cloudy day",
    "weather__day-cloudy-2": "Cloudy day",
    "weather__day-cloudy-3": "Cloudy day",
    "weather__day-mostlyc": "Day mostlyc",
    "weather__day-mostlyc-2": "Day mostlyc",
    "weather__day-mostlyc-3": "Day mostlyc",
    "weather__day-rainy-1": "Rainy day",
    "weather__day-rainy-2": "Rainy day",
    "weather__day-rainy-3": "Rainy day",
    "weather__day-snowy-1": "Snowy day",
    "weather__day-snowy-2": "Snowy day",
    "weather__day-snowy-3": "Snowy day",
    "weather__day-thunder": "Thunder day",
    "weather__day-thunder-rainy-2": "Thunder rainy day",
    "weather__day-thunder-rainy-3": "Thunder rainy day",
    "weather__day-thunder-rainy-4": "Thunder rainy day",
    "weather__empty": "Empty",
    "weather__night": "Night",
    "weather__night-cloudy-1": "Cloudy night",
    "weather__night-cloudy-2": "Cloudy night",
    "weather__night-cloudy-3": "Cloudy night",
    "weather__night-mostlyc": "Mostlyc night ",
    "weather__night-mostlyc-2": "Mostlyc night ",
    "weather__night-mostlyc-3": "Mostlyc night ",
    "weather__night-rainy-1": "Rainy night",
    "weather__night-rainy-2": "Rainy night",
    "weather__night-rainy-3": "Rainy night",
    "weather__night-snowy-1": "Snowy night",
    "weather__night-snowy-2": "Snowy night",
    "weather__night-snowy-3": "Snowy night",
    "weather__night-thunder": "Thunder night",
    "weather__night-thunder-rainy": "Thunder rainy night",
    "weather__night-thunder-rainy-2": "Thunder rainy night",
    "weather__night-thunder-rainy-3": "Thunder rainy night",
    "weather__night-thunder-rainy-4": "Thunder rainy night",
    "weather__precip": "Precip",
    "weather__pressure": "Pressure",
    "weather__raindrop": "Raindrop",
    "weather__rainy": "Rainy",
    "weather__rainy-2": "Rainy",
    "weather__rainy-3": "Rainy",
    "weather__rainy-4": "Rainy",
    "weather__snowy-1": "Snowy",
    "weather__snowy-2": "Snowy",
    "weather__snowy-3": "Snowy",
    "weather__snowy-rainy-1": "Wet snow",
    "weather__snowy-rainy-2": "Wet snow",
    "weather__snowy-rainy-3": "Wet snow",
    "weather__snowy-rainy-4": "Wet snow",
    "weather__thermometer": "Thermometer",
    "weather__thunder": "Thunder",
    "weather__thunder-rainy": "Thunder rainy",
    "weather__thunder-rainy-2": "Thunder rainy",
    "weather__thunder-rainy-3": "Thunder rainy",
    "weather__thunder-rainy-4": "Thunder rainy",
    "weather__weather": "Weather",
    "weather__wind": "Wind",
    //wizard
    "wizard-info": "Info",
    "wizard-key": "Key",
    "wizard-sensors": "Sensors",
    "wizard-settings": "Settings",
    //???
    "head__logo": "Logo",
    "direction_track": "Direction track",
    "download-video": "Download video",
    //"empty": "",
    "Exclude": "Exclude",
    "fuel": "Fuel",
    "munit.png": "munit",
    "play-button": "Play",
    "locator-icon": "Locator"
}