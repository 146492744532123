const RESOURCE_NAME = '';
import axios from "axios";
const baseURL = 'https://files.prometheuspro.us'

export default () => ({
    add(data, params) {
        return axios.post(`${RESOURCE_NAME}/upload`, data, {
            params, baseURL,
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
    },
    save(data, params) {
        return axios.post(`${RESOURCE_NAME}/save`, data, {
            params, baseURL,
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
    },
});