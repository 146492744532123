<template>
    <section
        class="section section_type_info is_new-design"
        id="sectionInfoByRoute"
        :class="{'section_status_open': showSectionInfoByMeta}"
    >
    </section>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: "SectionInfoByRoute",
        props: [],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ]),
            routeMeta() {
                return this.$route.meta
            },
            showSectionInfoByMeta() {
                return typeof this.routeMeta?.sectionInfoByRoute === 'function' ?
                    this.routeMeta?.sectionInfoByRoute(this.$route) :
                    this.routeMeta?.sectionInfoByRoute
            },
        },
        methods: {
            ...mapActions([
            ]),
        },
        updated: function() {
            //console.log('SectionInfo updated', this.sectionInfo_component, {...this.sectionInfo_props});
        },
        mounted() {
            // console.log('section_type_info', this)
        },
    }
</script>

<style scoped>

</style>