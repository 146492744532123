// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        tiveLocationsFullLoad: false,
        tiveLocationsLiteLoad: false,
        tiveLocations: [],
    },
    actions: {
        fetchTiveLocations/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveLocations', time: Date.now() / 1000})

                const params = getApiProps('tiveLocations', args)
                this.$api.tivelocations.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveLocations', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveLocationsLiteLoad', true)
                        commit('settiveLocationsFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveLocations', inprogress: false})
                    });
            })
        },
        fetchTiveLocation/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveLocation', time: Date.now() / 1000})

                const params = getApiProps('tiveLocations')
                this.$api.tivelocations.find(id,{...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateTiveLocation', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveLocation', inprogress: false})
                    });
            })
        },

        saveTiveLocation({dispatch}, location) {
            let fn = (location.id) ? 'updateTiveLocation' : 'createTiveLocation'
            return dispatch(fn, location);
        },
        createTiveLocation({commit}, location) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveLocations')
                this.$api.tivelocations.create(location, params)
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateTiveLocation', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveLocation({commit}, location) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveLocations')
                this.$api.tivelocations.update(location.id, location, params)
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateTiveLocation', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveLocation({commit}, locationId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveLocations')
                this.$api.tivelocations.delete(locationId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteTiveLocation', locationId)
                            else commit('updateTiveLocation', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        settiveLocationsFullLoad(state, FullLoad) {
            state.tiveLocationsFullLoad = state.tiveLocationsFullLoad || FullLoad
        },
        setTiveLocationsLiteLoad(state, LitaLoad) {
            state.tiveLocationsLiteLoad = state.tiveLocationsLiteLoad || LitaLoad
        },

        setTiveLocations(state, nTiveLocations) {
            nTiveLocations = nTiveLocations.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveLocations.sort(sortByName)
            state.tiveLocations = nTiveLocations
        },

        updateTiveLocations(state, nTiveLocations) {
            if (!state.tiveLocations.length) {
                nTiveLocations = nTiveLocations.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveLocations.sort(sortByName)
                state.tiveLocations = nTiveLocations
                // const chunks = arraySplitIntoChunks(nTiveLocations)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveLocations.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveLocations.forEach(function (nLocation) {
                if (nLocation?.name) nLocation.name_ = nLocation.name.toLocaleLowerCase()
                let i = state.tiveLocations.findIndex(u => (u.id == nLocation.id))
                if (i < 0) {
                    state.tiveLocations.push(nLocation) //(Object.freeze(nLocation))
                } else
                if (!state.tiveLocationsFullLoad || state.tiveLocations[i][changedField] != nLocation[changedField]) {
                    updateObjectByDiff(state.tiveLocations[i], nLocation)
                    // delete nLocation.id
                    // nLocation = {...state.tiveLocations[i], ...nLocation}
                    // state.tiveLocations[i] = nLocation //Object.freeze(nLocation)
                }
            })

        },
        filterTiveLocations(state, nTiveLocations) {
            // let Ids = state.tiveLocations.map(u=> u.id)
            let nIds = nTiveLocations.map(u => u.id)
            let removedIds = state.tiveLocations.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.tiveLocations.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.tiveLocations.splice(i, 1)
                }
            })
        },
        updateTiveLocation(state, nLocation) {
            if (nLocation?.name) nLocation.name_ = nLocation.name.toLocaleLowerCase()
            let i = state.tiveLocations.findIndex(u => (u.id == nLocation.id))
            if (i < 0) {
                state.tiveLocations.push(nLocation) //(Object.freeze(nLocation))
            } else
            //if (!state.tiveLocationsFullLoad || state.tiveLocations[i][changedField] != nLocation[changedField])
            {
                updateObjectByDiff(state.tiveLocations[i], nLocation)
                // delete nLocation.id
                // nLocation = {...state.tiveLocations[i], ...nLocation}
                // state.tiveLocations[i] = nLocation //Object.freeze(nLocation)
            }
        },
        deleteTiveLocation(state, locationId) {
            let i = state.tiveLocations.findIndex(u => (u.id == locationId))
            if (i != -1) {
                state.tiveLocations.splice(i, 1)
            }
        },

        clearTiveLocations(state) {
            state.tiveLocations = []
            state.tiveLocationsFullLoad = false
        },
    },
    getters: {
        isTiveLocationsFullLoad(state) {
            return state.tiveLocationsFullLoad
        },
        isTiveLocationsLiteLoad(state) {
            return state.tiveLocationsLiteLoad
        },
        tiveLocations(state) {
            return state.tiveLocations
        },
        tiveLocationsByIds(state) {
            return state.tiveLocations
                .reduce((tiveLocationsByIds, location) => {
                tiveLocationsByIds[location.id] = location
                return tiveLocationsByIds
            }, {})
        },
        sortedTiveLocationsIds(state) {
            let tiveLocations = state.tiveLocations
            tiveLocations.sort(sortByName)
            return tiveLocations.map(u => u.id)
        },
    }
}
