<template>
    <teleport :to="teleport" :disabled="!teleport">
        <vue-final-modal
            :click-to-close="false"
            :esc-to-close="true"
            v-slot="{ params }"
            :modelValue="true"
            classes="modal-container"
            :class="modalClass"
            :content-class="['modal-content', getTheme, productClass]"
        >
            <!--         v-on="$listeners" -->
            <button class="modal__close button button_view_icon button_theme_primary" @click="onCancel" v-if="showCansel">
                <span class="icon"> <span class="icon__symbol"><SvgIcon class="icon" name="common__close"/></span> </span>
            </button>
            <span class="modal__title">
                {{ title }}
            </span>
            <div class="modal__content">
                <slot v-if="params" v-bind:params="params"></slot>
            </div>
            <div class="modal__action">
                <template v-if="onceClick">
                    <button class="button button_view_base mr__8 modal-ok-btn"
                            :class="btnOkClass"
                            @click.once="onOk()"
                            v-if="showOk"
                    >
                        {{ btnOkText || $t('buttons.Ok') }}
                    </button>
                    <button class="button button_view_base modal-close-btn"
                            :class="btnCanselClass"
                            @click.once="onCancel()"
                            v-if="showCansel"
                    >
                        {{ btnCanselText || $t('buttons.Cancel') }}
                    </button>
                </template>
                <template v-else>
                    <button class="button button_view_base mr__8 modal-ok-btn"
                            :class="btnOkClass"
                            @click="onOk()"
                            v-if="showOk"
                    >
                        {{ btnOkText || $t('buttons.Ok') }}
                    </button>
                    <button class="button button_view_base modal-close-btn"
                            :class="btnCanselClass"
                            @click="onCancel()"
                            v-if="showCansel"
                    >
                        {{ btnCanselText || $t('buttons.Cancel') }}
                    </button>
                </template>
            </div>
        </vue-final-modal>
    </teleport>
</template>

<script>
// import { VueFinalModal } from 'vue-final-modal'
import {mapGetters} from "vuex";

export default {
    name: 'CustomModal',
    inheritAttrs: false,
    emits: ['confirm', 'cancel'],
    props: {
        'teleport': {
            type: String,
            default: () => 'body'
        },
        'title': String,
        'buttons': Array,
        // 'params',
        'confirm_text': String,
        'cancel_text': String,
        'fnOk': Function,
        'fnCancel': Function,
        'modalClass': [String],
        'btnOkText': String,
        'btnCanselText': String,
        'btnOkClass': {
            type: String,
            default: () => 'button_theme_danger'
        },
        'btnCanselClass': {
            type: String,
            default: () => 'button_theme_neutral'
        },
        'onceClick': {
            type: Boolean,
            default: () => true,
        },
    },
    data() {
        return {}
    },
    computed:{
        ...mapGetters([
            "getTheme",
        ]),
        showCansel(){
            return !!this.buttons.find(b => b.id == 'cancel')
        },
        showOk(){
            return !!this.buttons.find(b => b.id == 'ok')
        },
        productClass() {
            return this.$product+'-'+this.$site
        }
    },
    methods:{
        // confirm() {
        //     if( typeof this.fnOk == 'function'){
        //         this.fnOk()
        //     }
        //     this.showPopup = false
        // },
        // cancel() {
        //     this.showPopup = false
        // },

        onOk(){
            this.buttons.find(b => b.id == 'ok').fn()//???
            //close() //???
        },
        onCancel(){
            this.buttons.find(b => b.id == 'cancel').fn()//???
            //close() //???
        }
    }
    // comments: { 'vue-final-modal': VueFinalModal },
}
</script>

<style lang="scss">
$fontFamely: Montserrat, sans-serif;
:root {
    --vue-final-modal-bg: white;
    --vue-final-modal-title-colro: #00265c;
    --vue-final-modal-title-box-shadow: 0px 6px 4px 0px #FBFBFB;
}
.prometheus-portal {
    &.theme-dark {
        --vue-final-modal-bg: #2B4B73;
        --vue-final-modal-title-box-shadow: 0px 6px 2px 0px #224674;
        --vue-final-modal-title-colro: white;
    }
}
.vfm {
    z-index: 9999;
    .vfm__overlay.vfm--overlay {
        background: rgba(var(--color-neutral-950-rgb), 0.3);
    }

    .vfm__content.modal-content {
        min-width: 24rem;
        min-height: 16rem;
        padding: 0;
        border: none;
        overflow: hidden;
        border-radius: var(--border-radius-md);
        box-shadow: 0 0 1rem rgba(var(--color-neutral-950-rgb), 0.05);
    }

    .modal__close {
        margin-top: 12px;
        margin-right: 4px;
        z-index: 2;
    }

    .modal__title {
        //padding-right: 5rem;
        z-index: 1;
        margin: 0 !important;
        height: 5rem;


        display: flex;
        align-items: center;
        padding: 1.5rem 5rem 1.5rem 1.5rem;
        @include font(24px, 28px, 400, none, normal, var(--color-font), $fontFamely);
        font-size: 24px !important;
        font-weight: 400 !important;
        background: var(--color-white);
        box-shadow: 0 0 1rem rgba(var(--color-neutral-950-rgb), 0.05);
    }

    .modal__content {
        padding: 1.25rem 1.5rem;
        min-height: 130px;
        max-height: 90vh;
        @include font(16px, 20px, 400, none, normal, var(--color-font));
    }

    .modal__action {
        padding: 1rem 1.5rem !important;
        display: flex;
        height: 4rem;
        justify-content: flex-start !important;
        box-shadow: 0 0 1rem rgba(var(--color-neutral-950-rgb), 0.05);

        button {
            width: 100%;
        }
    }
    .modal__title {
        margin: 0 2rem 0 0;
        font-size: 1.5rem;
        font-weight: 700;
        background: var(--vue-final-modal-bg);
        box-shadow: var(--vue-final-modal-title-box-shadow);
        color: var(--vue-final-modal-title-colro);
    }

    .modal__content {
        flex-grow: 1;
        overflow-y: auto;
        @include scroll(var(--color-neutral-25), var(--color-neutral-100), 6px);
    }

    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }

    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    .dark-mode div.modal-content {
        border-color: #2d3748;
        background-color: #1a202c;
    }

    .modal-ok-btn {
        background: red;
    }
    .theme-dark {
        .modal-ok-btn {
            border: none;
        }
    }
}
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content-left {
    .modal-container {
        justify-content: flex-start;
    }
}
.modal_hide_close-btn .modal-close-btn{
    display: none;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: var(--vue-final-modal-bg);
}
.modal-300 {
    .modal-content {
        max-width: 300px;
    }
}
.modal-500 {
    .modal-content {
        max-width: 500px;
    }
}
.modal-600 {
    .modal-content {
        max-width: 600px;
        width: 100%;
    }
}
</style>
<style scoped lang="scss">
.prometheus-portal {
    &.theme-dark {
        .modal__action {
            --button-view-them-netrul-bg: #EDF5FF;
            --button-view-them-netrul-color: #323B7A;
            --button-view-them-netrul-active-color: white;
            --button-primary-color: #2B7DDA;
        }
    }
}
</style>
