// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a?.measurementTime?.utc - b.a?.measurementTime?.utc;
}

export default {
    state: {
        tiveShipmentTracksFullLoad: false,
        tiveShipmentTracksLiteLoad: false,
        tiveShipmentTracks: [],
    },
    actions: {
        fetchTiveShipmentTracks/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                let id = args.id;
                if (!getters.apiToken || !id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveShipmentTracks', time: Date.now() / 1000})

                const params = getApiProps('tiveShipmentTracks', args)
                this.$api.tiveshipmenttracks.shipmentTrack(id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            commit('updateTiveShipmentTracks', response.data)
                            resolve(response.data)
                        } else {
                            resolve(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveShipmentTracksLiteLoad', true)
                        commit('setTiveShipmentTracksFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveShipmentTracks', inprogress: false})
                    });
            })
        },


    },
    mutations: {
        setTiveShipmentTracksFullLoad(state, FullLoad) {
            state.tiveShipmentTracksFullLoad = state.tiveShipmentTracksFullLoad || FullLoad
        },
        setTiveShipmentTracksLiteLoad(state, LitaLoad) {
            state.tiveShipmentTracksLiteLoad = state.tiveShipmentTracksLiteLoad || LitaLoad
        },

        setTiveShipmentTracks(state, nTiveShipmentTracks) {
            nTiveShipmentTracks = nTiveShipmentTracks.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveShipmentTracks.sort(sortByName)
            state.tiveShipmentTracks = nTiveShipmentTracks
        },

        updateTiveShipmentTracks(state, nTiveShipmentTracks) {
            if (!state.tiveShipmentTracks.length) {
                nTiveShipmentTracks = nTiveShipmentTracks.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveShipmentTracks.sort(sortByName)
                state.tiveShipmentTracks = nTiveShipmentTracks
                // const chunks = arraySplitIntoChunks(nTiveShipmentTracks)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveShipmentTracks.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveShipmentTracks.forEach(function (nShipmentTrack) {
                if (nShipmentTrack?.name) nShipmentTrack.name_ = nShipmentTrack.name.toLocaleLowerCase()
                let i = state.tiveShipmentTracks.findIndex(u => (u.xid == nShipmentTrack.xid))
                if (i < 0) {
                    state.tiveShipmentTracks.push(nShipmentTrack) //(Object.freeze(nShipmentTrack))
                } else {
                    // if (!state.tiveShipmentTracksFullLoad || state.tiveShipmentTracks[i][changedField] != nShipmentTrack[changedField]) {
                    updateObjectByDiff(state.tiveShipmentTracks[i], nShipmentTrack)
                    // delete nShipmentTrack.xid
                    // nShipmentTrack = {...state.tiveShipmentTracks[i], ...nShipmentTrack}
                    // state.tiveShipmentTracks[i] = nShipmentTrack //Object.freeze(nShipmentTrack)
                }
            })

        },
        filterTiveShipmentTracks(state, nTiveShipmentTracks) {
            // let Ids = state.tiveShipmentTracks.map(u=> u.xid)
            let nIds = nTiveShipmentTracks.map(u => u.xid)
            let removedIds = state.tiveShipmentTracks.filter(u => !nIds.includes(u.xid)).map(u => u.xid)
            removedIds.forEach(removedId => {
                let i = state.tiveShipmentTracks.findIndex(u => (u.xid == removedId))
                if (i != -1) {
                    state.tiveShipmentTracks.splice(i, 1)
                }
            })
        },
        updateTiveShipmentTrack(state, nShipmentTrack) {
            if (nShipmentTrack?.name) nShipmentTrack.name_ = nShipmentTrack.name.toLocaleLowerCase()
            let i = state.tiveShipmentTracks.findIndex(u => (u.xid == nShipmentTrack.xid))
            if (i < 0) {
                state.tiveShipmentTracks.push(nShipmentTrack) //(Object.freeze(nShipmentTrack))
            } else {
                // if (state.tiveShipmentTracksFullLoad || state.tiveShipmentTracks[i][changedField] != nShipmentTrack[changedField]) {
                updateObjectByDiff(state.tiveShipmentTracks[i], nShipmentTrack)
                // delete nShipmentTrack.xid
                // nShipmentTrack = {...state.tiveShipmentTracks[i], ...nShipmentTrack}
                // state.tiveShipmentTracks[i] = nShipmentTrack //Object.freeze(nShipmentTrack)
            }
        },

        clearTiveShipmentTracks(state) {
            state.tiveShipmentTracks = []
            state.tiveShipmentTracksFullLoad = false
        },
    },
    getters: {
        isTiveShipmentTracksFullLoad(state) {
            return state.tiveShipmentTracksFullLoad
        },
        isTiveShipmentTracksLiteLoad(state) {
            return state.tiveShipmentTracksLiteLoad
        },
        getTiveShipmentTracks(state) {
            return state.tiveShipmentTracks
        },
    }
}
