<template>
    <section class="section section_type_main-side is_new-design" :class="$attrs.class">
        <!--- Section type List --->
        <SectionList v-show="!isGuest && hasList" v-if="!isGuest && getAppUserId"/>

        <!--- Section type Basic --->
        <SectionBasic />
    </section>
</template>

<script>
    import SectionList from '@/components/SectionList.vue'
    import SectionBasic from '@/components/SectionBasic.vue'
    import {mapGetters} from "vuex";

    export default {
        name: "SectionMainside",
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "apiToken",
                "isGuest",
                "getAppUserId",
            ]),
            hasList() {
                return (this.$route.meta.list)
            },
        },
        components: {
            SectionList, SectionBasic
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style lang="scss" scoped>
.section {
    &.section_type_main-side {
        display: flex;
        width: 100%;
    }
}
</style>