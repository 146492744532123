<template>
    <!--- Section type Global --->
    <section id="app"
             class="section section_type_global is_new-design"
             :class="[headerClasses, getTheme]"
             @click="onClick"
    >
        <!-- header -->
        <SectionHeader v-show="showHeader"/>
        <!--- Section type Aside --->
        <SectionAside v-show="!isGuest && hasAside"/>
        <!--- Section type Main-side --->
        <SectionMainside :class="sectionGlobalClass.class"/>
        <!--- -- --->
    </section>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import SectionAside from '@/components/SectionAside.vue'
import SectionMainside from '@/components/SectionMainside.vue'
import SectionHeader from '@/components/SectionHeader.vue'
import consts from "@/consts/intuit.";
import {computed} from "vue";
import {useToast} from "vue-toastification";
import {treeToFlat} from "@progress/kendo-vue-treelist";
// import {diffObjects} from "@/lib/lib";
// import PageLoading from "@/views/PageLoading.vue";

export default {
    name: 'sectionGlobal',
    components: {
        // PageLoading,
        SectionAside,
        SectionMainside,
        SectionHeader,
    },
    data() {
        return {
            pathname: null,
            urlSearchParams: null,
            interval: null,
            sectionGlobalClass: {},
            toast: useToast()
        }
    },
    provide() {
        return {
            sectionGlobalClass: computed(() => this.sectionGlobalClass),
            appUserReloadValue: computed(() => this.appUserReloadValue),
        }
    },
    computed: {
        ...mapGetters([
            "getAppUserId",
            "getAppUser",
            "isGuest",
            "apiToken",
            "apiTokenExpiresIn",
            "getUserStorageKey",
            "units",
            "getTheme",
        ]),
        routeName(){
            return this.$route.name
        },
        routeFilter(){
            return this.$route.params
        },
        routeQueryFilter(){
            return this.$route.query
        },
        routeMeta(){
            return this.$route.meta
        },
        mainRouteName(){
            return this.routeMeta?.mainRouteName
        },
        units_length(){
            return this.units.length
        },
        hasAside() {
            return !(this.routeMeta?.showAsideMenu === false)
        },
        headerClasses() {
            return !this.isGuest && [this.$product, (this.$product+'-'+this.$site)]
        },
        showHeader(){
            return !this.isGuest && this.$consts.app.$isPrometheus
        },
        appUserReloadValue(){
            return this.getAppUserId ? /*JSON.stringify*/({
                units_of_measure: this.getAppUser?.units_of_measure,
                time_zone: this.getAppUser?.time_zone,
                lang: this.getAppUser?.lang
            }) : null
        },
    },
    watch: {
        apiToken: {
            handler: function (newVl, oldVl) {
                if(!(newVl > ' ') && (oldVl > ' ')){
                    this.clearAllData()
                    if(!this.routeMeta.isGuest) {
                        this.$router.push('/login')
                    }
                } /*else
                if(newVl > ' ' && !(oldVl > ' ') && this.routeName === 'Login'){
                    this.$router.push('/')
                }*/
            }
        },
        getAppUser:{
            handler: function (user) {
                if(this.$site === 'admin' && user.id && !user.is_admin) {
                    this.onLogout()
                }
            }
        },
        appUserReloadValue:{
            handler: function (nVal, oVal) {
                if(!!oVal && !!nVal) {
                    this.reloadAppProps()
                }
            },
            deep: true
        }
    },
    created() {
        let routes =
            treeToFlat(this.$router.options.routes, 'name', 'children')
                .map(route => {
                    return {
                        name: route.name,
                        path: route.path,
                        meta: route.meta,
                    }
                })
        this.setRoutes(routes);

        if(this.$isAdminSite) {
            let entriScript = document.createElement('script')
            entriScript.src = 'https://cdn.goentri.com/entri.js'
            entriScript.type = 'text/javascript'
            document.body.appendChild(entriScript)
        }

        let uri = window.location.search.substring(1);
        this.urlSearchParams = new URLSearchParams(uri);
        this.pathname = window.location.pathname

        this.calcWidth()
        window.addEventListener('resize', this.calcWidth)
        this.setFavicon()
        this.setTitle()
    },
    mounted() {
        //locale
        let lang = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.lang')
        lang = this.$consts.langs.map(l => l.id).includes(lang)? lang : 'en'
        this.$i18n.locale = lang
        let theme = localStorage.getItem(process.env.VUE_APP_PRODUCT + '.theme')
        this.setTheme((this.$consts.app.$isIntuit ? null : theme) || this.$consts.themes.white)

        //tokens
        let token = this.urlSearchParams.get("access-token");
        if( this.pathname === "/login" && token) {
            //if(this.$isDevelopment) console.log("access-token", token)
            this.loginByToken(token)
                .then(() => {
                    this.fetchAppProps()
                })
                .catch((error) => {
                    if(this.$isDevelopment) console.error(error);
                })
        } else {
            token = localStorage.getItem(process.env.VUE_APP_PRODUCT + '.authtoken')
            // this.$cookies.get('authtoken')
            if(token) {
                this.refreshTokens(token)
                    .then(() => {
                        this.fetchAppProps()
                    })
                    .catch((error) => {
                        if (this.$isDevelopment) console.error(error);
                    })
            }
        }
        // if (!this.isGuest) {
        //     this.fetchAppProps()
        // }

        this.interval = setInterval(() => {
            let t = Math.round(Date.now() / 1000)
            this.incrementUnitsLmsgTimeAgo()
            this.fetchByTimer({time: t, page: this.mainRouteName || this.routeName})
            if(t % 10 === 0) {
                this.calcTokensExpire(t)
            }
            if(t % (3600/4) < 2) {
                this.calcDateToday()
            }
            if(this.apiTokenExpiresIn < 300 && (this.apiToken>' ')) {
                if(t % 10 === 5) {
                    this.refreshTokens()
                        .catch((error) => {
                            if (this.$isDevelopment) console.error(error);
                        })
                }
            }
        }, 1000)
    },
    beforeUnmount() {
        if(this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
    },
    methods: {
        ...mapMutations([
            //"calcTokensExpire",
            "setShowFilterDropdownMenu",
            "setShowUserMenu",
            "clearUnits",
            "setShortWidth",
            "setWindowWidth",
            "setRoutes",
            "setTheme",
            "calcDateToday",
        ]),
        ...mapActions([
            "loginByToken",
            "refreshTokens",
            "calcTokensExpire",
            "fetchAppProps",
            "reloadAppProps",
            "clearAllData",
            "incrementUnitsLmsgTimeAgo",
            "fetchByTimer",
            "logout",
        ]),
        onLogout() {
            this.toast.error(this.$t('Access denied'))
            this.logout()
        },
        calcWidth(){
            this.setShortWidth(window.innerWidth < consts.shortWidth)
            this.setWindowWidth(window.innerWidth)
        },
        onClick(e){
            //ToDo fix (use click-outside)
            // https://unreal-stuff.ru/articles/js/vue/vue-js-solutions/vue3-click-outside/
            // https://stackoverflow.com/questions/36170425/detect-click-outside-element
            //if(this.$isDevelopment) console.warn('onClick', e.target)
            // Close the dropdown if the user clicks outside of it
            let onDropdownMenu = e.target.matches('.filter-search__dropdown-menu *')
                || e.target.matches('.button_dropdown-menu') || e.target.matches('.button_dropdown-menu *')
            if(!onDropdownMenu) {
                this.setShowFilterDropdownMenu(false)
            }
            this.setShowUserMenu(false)
        },
        isAuthenticated(){
            let token = localStorage.getItem(process.env.VUE_APP_PRODUCT+'.authtoken')
            return (token && token>'')
        },
        setFavicon(){
            document
                .querySelector('link[rel="icon"]')
                .setAttribute('href', this.$config.customize.baseURL+'/site-customize/favicon?t='+(Date.now()))
        },
        setTitle(){
            let title = process.env.VUE_APP_TITLE
            fetch( this.$config.customize.baseURL+'/site-customize/site_name?t='+(Date.now()))
                .then((response) => {
                    //console.log(response)
                    if(response.ok){
                        return response.json();
                    } else {
                        console.warn(response.status, response)
                    }
                })
                .then((data) => {
                    if (data && data.site_name > '') {
                        title = data.site_name
                    }
                })
                .catch(e => {
                    console.error('There has been a problem with your fetch operation: ' + e.message);
                })
                .finally(() => {
                    document.querySelector('title').innerHTML = title
                })
            ;
        }
    }
}
</script>

<style lang="scss">
@import "~@/scss/main.scss";
.section_type_global {
    display: flex;
    height: 100vh;

    transition: all var(--transition-duration-md) ease-in-out;

    &.section_has-aside {
        grid-template-columns: auto 1fr;
    }
}
.prometheus {
    $headerHeight: 64px;

    display: grid;
    grid-template-areas:
    "header header"
    "aside main-side";
    grid-template-rows: $headerHeight 1fr;
    grid-template-columns: 70px 1fr;

    .aside-menu_wrap{
        grid-area: aside;
        height: 100%;
        grid-template-rows: 1fr auto;
    }
    .section_type_main-side{
        grid-area: main-side;
    }
}
.prometheus-portal {
    $headerHeight: 50px;
    grid-template-rows: $headerHeight 1fr;
}
.prometheus-admin {
    grid-template-columns: 104px 1fr;
}
.section.section_type_login {
    background: url("~@/assets/img/empty1px.png");
    background-size: cover;
}

.section.section_type_form-page {
    background: url("~@/assets/img/empty1px.png");
    background-size: cover;
}
</style>
