// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
import consts from "@/consts";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        tiveShipmentsFullLoad: false,
        tiveShipmentsLiteLoad: false,
        tiveEditShipment: {},
        tiveShipments: [
            // {
            //     id: 0,
            //     shipmentLegs: [],
            // }
        ],
    },
    actions: {
        fetchTiveShipments/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveShipments', time: Date.now() / 1000})

                const params = getApiProps('tiveShipments', args)
                this.$api.tiveshipments.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveShipments', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveShipmentsLiteLoad', true)
                        commit('setTiveShipmentsFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveShipments', inprogress: false})
                    });
            })
        },
        fetchTiveShipment/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveShipment', time: Date.now() / 1000})

                const params = {}//getApiProps('tiveShipments', args)
                this.$api.tiveshipments.find(id,{...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveShipment', response?.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveShipment', inprogress: false})
                    });
            })
        },

        fetchTiveShipmentsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken) {
                return
            }
            dispatch('setLastCall', {name: 'fetchTiveShipmentsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchTiveShipments', args)
                .then((data) => {
                    commit('updateTiveShipments', data)
                    return dispatch('fetchTiveShipments', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterTiveShipments', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchTiveShipmentsChanged', inprogress: false})
                });
        },

        saveTiveShipment({dispatch}, shipment) {
            let fn = (shipment.id) ? 'updateTiveShipment' : 'createTiveShipment'
            return dispatch(fn, shipment);
        },
        createTiveShipment({commit}, shipment) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipments')
                this.$api.tiveshipments.create(shipment, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveShipment', response?.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveShipment({commit}, shipment) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipments')
                this.$api.tiveshipments.update(shipment.id, shipment, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error && !response.data.errors) {
                            commit('updateTiveShipment', response?.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        completeTiveShipment({dispatch}, shipmentId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipments')
                this.$api.tiveshipments.completeShipment(shipmentId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && response?.data && !response?.data?.error && !response?.data?.errors) {
                            dispatch('fetchTiveShipment', response.data.id)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveShipment({commit}, shipmentId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveShipments')
                this.$api.tiveshipments.delete(shipmentId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && !response?.data?.error && !response?.data?.errors) {
                            if (!response.data.id) commit('deleteTiveShipment', shipmentId)
                            else commit('updateTiveShipment', response?.data?.id)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setTiveShipmentsFullLoad(state, FullLoad) {
            state.tiveShipmentsFullLoad = state.tiveShipmentsFullLoad || FullLoad
        },
        setTiveShipmentsLiteLoad(state, LitaLoad) {
            state.tiveShipmentsLiteLoad = state.tiveShipmentsLiteLoad || LitaLoad
        },
        setTiveEditShipment(state, shipment) {
            state.tiveEditShipment = shipment
        },

        setTiveShipments(state, nTiveShipments) {
            nTiveShipments = nTiveShipments.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveShipments.sort(sortByName)
            state.tiveShipments = nTiveShipments
        },

        updateTiveShipments(state, nTiveShipments) {
            if (!state.tiveShipments.length) {
                nTiveShipments = nTiveShipments.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveShipments.sort(sortByName)
                state.tiveShipments = nTiveShipments
                // const chunks = arraySplitIntoChunks(nTiveShipments)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveShipments.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveShipments.forEach(function (nShipment) {
                if (nShipment?.name) nShipment.name_ = nShipment.name.toLocaleLowerCase()
                let i = state.tiveShipments.findIndex(u => (u.id == nShipment.id))
                if (i < 0) {
                    state.tiveShipments.push(nShipment) //(Object.freeze(nShipment))
                } else {
                    // if (!state.tiveShipmentsFullLoad/* || state.tiveShipments[i][changedField] != nShipment[changedField]*/) {
                    updateObjectByDiff(state.tiveShipments[i], nShipment)
                    // delete nShipment.id
                    // nShipment = {...state.tiveShipments[i], ...nShipment}
                    // state.tiveShipments[i] = nShipment //Object.freeze(nShipment)
                }
            })

        },
        filterTiveShipments(state, nTiveShipments) {
            // let Ids = state.tiveShipments.map(u=> u.id)
            let nIds = nTiveShipments.map(u => u.id)
            let removedIds = state.tiveShipments.filter(u => u.id > 0 && !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.tiveShipments.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.tiveShipments.splice(i, 1)
                }
            })
        },
        updateTiveShipment(state, nShipment) {
            if (nShipment?.name) nShipment.name_ = nShipment.name.toLocaleLowerCase()
            let i = state.tiveShipments.findIndex(u => (u.id == nShipment.id))
            if (i < 0) {
                state.tiveShipments.push(nShipment) //(Object.freeze(nShipment))
            } else {
                // if (state.tiveShipmentsFullLoad || state.tiveShipments[i][changedField] != nShipment[changedField]) {
                updateObjectByDiff(state.tiveShipments[i], nShipment)
                console.log(state.tiveShipments[i])
                // delete nShipment.id
                // nShipment = {...state.tiveShipments[i], ...nShipment}
                // state.tiveShipments[i] = nShipment //Object.freeze(nShipment)
            }
        },
        deleteTiveShipment(state, shipmentId) {
            let i = state.tiveShipments.findIndex(u => (u.id == shipmentId))
            if (i != -1) {
                state.tiveShipments.splice(i, 1)
            }
        },

        clearTiveShipments(state) {
            state.tiveShipments = []
            state.tiveShipmentsFullLoad = false
        },
    },
    getters: {
        isTiveShipmentsFullLoad(state) {
            return state.tiveShipmentsFullLoad
        },
        isTiveShipmentsLiteLoad(state) {
            return state.tiveShipmentsLiteLoad
        },
        tiveShipments(state) {
            return state.tiveShipments
        },
        tiveShipmentsByIds(state) {
            return state.tiveShipments
                .reduce((tiveShipmentsByIds, shipment) => {
                tiveShipmentsByIds[shipment.id] = shipment
                return tiveShipmentsByIds
            }, {})
        },
        sortedTiveShipmentsIds(state) {
            let tiveShipments = state.tiveShipments
            tiveShipments.sort(sortByName)
            return tiveShipments.map(u => u.id)
        },
        getTiveEditShipment(state) {
            return state.tiveEditShipment
        }
    }
}
