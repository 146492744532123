export default {
    state: {},
    actions: {
        //ToDo remove ???
        setGeoitem({dispatch, commit}, id) {
            let val = id ? [+id] : []
            commit('setAllGeoitemsShowOnMap', val)
            dispatch('setMapBoundsByGeozones', val)
        },
    },
    mutations: {},
    getters: {},
}
