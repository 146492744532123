<template>
    <div class="symbol__image-status flex align-items-canter flex-gap-5" :class="params.icon_class">
        <SvgIcon class="icon" :name="params.icon"/>
        <span class="unit-indicators__text" v-if="text">{{ $t('Unit.signal.' + params.text) }}</span>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    setup() {
        return {
            statuses: {
                default: {
                    bg_icon: '',
                    icon_class: 'connection_status_not-online-long-time',
                    icon: 'status_offline',
                    text: 'Not signal'
                },
                online: {
                    bg_icon: '',
                    icon_class: 'connection_status-online',
                    icon: 'status_online',
                    text: 'Online'
                },
                onlineRecently: {
                    bg_icon: '',
                    icon_class: 'connection_status-online-recently',
                    icon: 'status_online',
                    text: 'Online recently'
                },
                offlineRecently: {
                    bg_icon: '',
                    icon_class: 'connection_status_not-online-recently',
                    icon: 'status_offline',
                    text: 'Offline recently'
                },
                offline: {
                    bg_icon: '',
                    icon_class: 'connection_status_not-online-long-time',
                    icon: 'status_offline',
                    text: 'Offline'
                }
            },
        }
    },
    name: 'UnitInfo_indicator_connection',
    props: {
        unitId: [String, Number],
        text: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters([
            "unitsLmsgsByIds",
            "unitsLmsgsTimeAgoByIds",
        ]),
        unitLmsgTimeAgo() {
            return this.unitsLmsgsTimeAgoByIds[this.unitId]
        },
        status() {
            let timeAgo = this.unitLmsgTimeAgo
            if (!timeAgo && timeAgo !== 0) {
                return '';
            }
            //if(timeAgo<0) timeAgo *= -1

            if (timeAgo <= 5 * 60) {
                return 'online'
            } else if (timeAgo <= 15 * 60) {
                return 'onlineRecently'
            } else if (timeAgo <= 60 * 60) {
                return 'offlineRecently'
            } else {
                return 'offline'
            }
        },
        params() {
            return this.statuses[this.status || 'default']
        }
    },
    updated: function () {
        // console.log(`UnitsList_unit_moving_status ${this.isMoving} updated`);
    },
}
</script>