import merge from "lodash/merge"

let env = process.env.NODE_ENV ? process.env.NODE_ENV : "*"
let config = {
    "env": env,
    "toast": {
        "optons": {
            "transition": "Vue-Toastification__bounce",
            "maxToasts": 20,
            "newestOnTop": true,

            "position": "top-right",
            "timeout": 5000,
            "closeOnClick": true,
            "pauseOnFocusLoss": true,
            "pauseOnHover": true,
            "draggable": true,
            "draggablePercent": 0.6,
            "showCloseButtonOnHover": false,
            "hideProgressBar": true,
            "closeButton": "button",
            "icon": true,
            "rtl": false
        }
    }
}

//intuit
import config_intuit from './intuit..js'
import config_intuit_production from './intuit.production.js'
import config_intuit_development from './intuit.development.js'
if (String(process.env.VUE_APP_PACKAGE).includes('intuit')) {
    config = merge(config, config_intuit)
    if (env === 'production') config = merge(config, config_intuit_production)
    if (env === 'development') config = merge(config, config_intuit_development)
}

//prometheus
import config_prometheus from './prometheus..js'
import config_prometheus_production from './prometheus.production.js'
import config_prometheus_development from './prometheus.development.js'
if (String(process.env.VUE_APP_PACKAGE).includes('prometheus')) {
    config = merge(config, config_prometheus)
    if (env === 'production') config = merge(config, config_prometheus_production)
    if (env === 'development') config = merge(config, config_prometheus_development)
}

export default config
