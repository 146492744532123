// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.deviceId - b.deviceId;
}

export default {
    state: {
        tiveDevicesFullLoad: false,
        tiveDevicesLiteLoad: false,
        tiveDevices: [],
    },
    actions: {
        fetchTiveDevices/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveDevices', time: Date.now() / 1000})

                const params = getApiProps('tiveDevices', args)
                this.$api.tivedevices.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveDevices', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveDevicesLiteLoad', true)
                        commit('settiveDevicesFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveDevices', inprogress: false})
                    });
            })
        },

        saveTiveDevice({dispatch}, device) {
            let fn = (device.deviceId) ? 'updateTiveDevice' : 'createTiveDevice'
            return dispatch(fn, device);
        },
        createTiveDevice({commit}, device) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveDevices')
                this.$api.tivedevices.create(device, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveDevice', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveDevice({commit}, device) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveDevices')
                this.$api.tivedevices.update(device.deviceId, device, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveDevice', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveDevice({commit}, deviceId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveDevices')
                this.$api.tivedevices.delete(deviceId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteTiveDevice', deviceId)
                            else commit('updateTiveDevice', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        settiveDevicesFullLoad(state, FullLoad) {
            state.tiveDevicesFullLoad = state.tiveDevicesFullLoad || FullLoad
        },
        setTiveDevicesLiteLoad(state, LitaLoad) {
            state.tiveDevicesLiteLoad = state.tiveDevicesLiteLoad || LitaLoad
        },

        setTiveDevices(state, nTiveDevices) {
            nTiveDevices = nTiveDevices.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveDevices.sort(sortByName)
            state.tiveDevices = nTiveDevices
        },

        updateTiveDevices(state, nTiveDevices) {
            if (!state.tiveDevices.length) {
                nTiveDevices = nTiveDevices.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveDevices.sort(sortByName)
                state.tiveDevices = nTiveDevices
                // const chunks = arraySplitIntoChunks(nTiveDevices)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveDevices.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveDevices.forEach(function (nDevice) {
                if (nDevice?.name) nDevice.name_ = nDevice.name.toLocaleLowerCase()
                let i = state.tiveDevices.findIndex(u => (u.deviceId == nDevice.deviceId))
                if (i < 0) {
                    state.tiveDevices.push(nDevice) //(Object.freeze(nDevice))
                } else
                if (!state.tiveDevicesFullLoad || state.tiveDevices[i][changedField] != nDevice[changedField]) {
                    updateObjectByDiff(state.tiveDevices[i], nDevice)
                    // delete nDevice.deviceId
                    // nDevice = {...state.tiveDevices[i], ...nDevice}
                    // state.tiveDevices[i] = nDevice //Object.freeze(nDevice)
                }
            })

        },
        filterTiveDevices(state, nTiveDevices) {
            // let Ids = state.tiveDevices.map(u=> u.deviceId)
            let nIds = nTiveDevices.map(u => u.deviceId)
            let removedIds = state.tiveDevices.filter(u => !nIds.includes(u.deviceId)).map(u => u.deviceId)
            removedIds.forEach(removedId => {
                let i = state.tiveDevices.findIndex(u => (u.deviceId == removedId))
                if (i != -1) {
                    state.tiveDevices.splice(i, 1)
                }
            })
        },
        updateTiveDevice(state, nDevice) {
            if (nDevice?.name) nDevice.name_ = nDevice.name.toLocaleLowerCase()
            let i = state.tiveDevices.findIndex(u => (u.deviceId == nDevice.deviceId))
            if (i < 0) {
                state.tiveDevices.push(nDevice) //(Object.freeze(nDevice))
            } else
            if (!state.tiveDevicesFullLoad || state.tiveDevices[i][changedField] != nDevice[changedField]) {
                updateObjectByDiff(state.tiveDevices[i], nDevice)
                // delete nDevice.deviceId
                // nDevice = {...state.tiveDevices[i], ...nDevice}
                // state.tiveDevices[i] = nDevice //Object.freeze(nDevice)
            }
        },
        deleteTiveDevice(state, deviceId) {
            let i = state.tiveDevices.findIndex(u => (u.deviceId == deviceId))
            if (i != -1) {
                state.tiveDevices.splice(i, 1)
            }
        },

        clearTiveDevices(state) {
            state.tiveDevices = []
            state.tiveDevicesFullLoad = false
        },
    },
    getters: {
        isTiveDevicesFullLoad(state) {
            return state.tiveDevicesFullLoad
        },
        isTiveDevicesLiteLoad(state) {
            return state.tiveDevicesLiteLoad
        },
        tiveDevices(state) {
            return state.tiveDevices
        },
        tiveDevicesByIds(state) {
            return state.tiveDevices
                .reduce((tiveDevicesByIds, device) => {
                tiveDevicesByIds[device.deviceId] = device
                return tiveDevicesByIds
            }, {})
        },
        sortedTiveDevicesIds(state) {
            let tiveDevices = state.tiveDevices
            tiveDevices.sort(sortByName)
            return tiveDevices.map(u => u.deviceId)
        },
    }
}
