<template>
    <div class="list__button-group" >
        <template v-for="(command, i) in menuCommands" :key="i">
            <component :is="'UnitsList_unit_menu_'+command.name"
                       :disabled="command.disabled"
                       :unitId="unitId"
                       v-if="command.view"
            />
        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations,} from "vuex";
    import { accessRight } from "@/lib/access-rights";

    import UnitsList_unit_menu_onmap from "@/components/units/UnitsList_unit_menu_onmap.vue";
    import UnitsList_unit_menu_tracking from "@/components/units/UnitsList_unit_menu_tracking.vue";
    import UnitsList_unit_menu_edit from "@/components/units/UnitsList_unit_menu_edit.vue";
    import UnitsList_unit_menu_totrack from "@/components/units/UnitsList_unit_menu_totrack.vue";
    import UnitsList_unit_menu_outputs from "@/components/units/UnitsList_unit_menu_outputs.vue";

    export default {
        name: 'UnitsList_unit_menu',
        inject: ['setRoute'],
        props: {
            unitId: [String, Number],
            commands: {
                type: [Array, Object],
                default: () => [
                    'outputs',
                    'totrack',
                    'edit',
                    //'tracking',
                ]
            }
        },
        components: {
            UnitsList_unit_menu_tracking,
            UnitsList_unit_menu_outputs,
            UnitsList_unit_menu_totrack,
            UnitsList_unit_menu_edit,
            UnitsList_unit_menu_onmap,
        },
        data() {
            return {
                accessRight,
                defCommands: [
                    {
                        name: 'outputs',
                        //view: () => this.hasOutputs && this.isUnitAccessRight_edit,
                        computedView: 'hasOutputs',
                    },
                    {
                        name: 'totrack',
                        //view: () => { return this.isAppMenuTrackAvailable && !this.isStationaryObj },
                        computedView: 'isUnitMenuToTrackAvailable',
                        //disabled: () => !this.isUnitsLmsgsFullLoad,
                        computedDisabled: 'isUnitsLmsgsNotFullLoad',
                    },
                    {
                        name: 'edit',
                        //view: () => { return this.accessRight > this.accessRight.read },
                        computedView: 'isUnitAccessRight_edit',
                        //disabled: () => !this.isUnitsFullLoad,
                        computedDisabled: 'isUnitsNotFullLoad',
                    },
                    {
                        name: 'tracking',
                        view: true,
                        disabled: false,
                    }
                ],
            }
        },
        computed: {
            ...mapGetters([
                "getAppUser",
                "getAppMenus",
                "unitsByIds",
                "isUnitsFullLoad",
                "isUnitsLmsgsFullLoad",
                "getHwTypesByType",
            ]),
            routeName() {
                return this.$route.name
            },
            routeFilter() {
                return this.$route.params
            },
            routeQueryFilter() {
                return this.$route.query
            },
            isAppMenuTrackAvailable(){
                return this.getAppMenus.find(m => m.id == 'tracks')
            },
            isUnitsNotFullLoad () {
                return !this.isUnitsFullLoad
            },
            isUnitsLmsgsNotFullLoad () {
                return !this.isUnitsLmsgsFullLoad
            },
            menuCommands() {
                return this.commands.map(c => {
                    if(typeof c === 'string'){
                        c = this.defCommands.find(cmd => cmd.name == c)
                    }
                    let disabled = c.computedDisabled ? this[c.computedDisabled] :
                        typeof c.disabled == 'function' ? c.disabled() : c.disabled
                    let view = c.computedView ? this[c.computedView] :
                        typeof c.view == 'function' ? c.view() : c.view
                    return {...c, disabled, view}
                })
            },
            unit() {
                return this.unitsByIds[this.unitId]
            },
            hasOutputs() {
                let outputs = this.unit?.outputs?.filter(o => o.name > '' && o.param > '') || []
                return !!outputs.length && this.isUnitAccessRight_edit
            },
            isUnitAccessRight_edit() {
                let unitAccessRight = this.unit?.access_right || 0
                return unitAccessRight >= this.accessRight.edit
            },
            isStationaryObj() {
                return !!this.getHwTypesByType[this.unit?.hw_type]?.is_stationary_obj
            },
            isUnitMenuToTrackAvailable(){
                return this.isAppMenuTrackAvailable && !this.isStationaryObj
            }
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
        },
    }
</script>

<style scoped>

</style>