// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.carrierId - b.carrierId;
}

export default {
    state: {
        tiveCarriersFullLoad: false,
        tiveCarriersLiteLoad: false,
        tiveStandardizedCarriers: [],
        tiveCarriers: [],
    },
    actions: {
        fetchTiveCarriers/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveCarriers', time: Date.now() / 1000})

                const params = getApiProps('tiveCarriers', args)
                this.$api.tivecarriers.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveCarriers', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveCarriersLiteLoad', true)
                        commit('settiveCarriersFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveCarriers', inprogress: false})
                    });
            })
        },
        fetchTiveCarrier/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveCarrier', time: Date.now() / 1000})

                const params = getApiProps('tiveCarriers', {})
                this.$api.tivecarriers.find(id, {...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveCarrier', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveCarrier', inprogress: false})
                    });
            })
        },
        fetchTiveStandardizedCarriers/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveStandardizedCarriers', time: Date.now() / 1000})

                const params = getApiProps('tiveStandardizedCarriers', args)
                this.$api.tivecarriers.getStandardizedCarriers({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveStandardizedCarriers', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveStandardizedCarriers', inprogress: false})
                    });
            })
        },

        saveTiveCarrier({dispatch}, carrier) {
            let fn = (carrier?.carrierId) ? 'updateTiveCarrier' : 'createTiveCarrier'
            return dispatch(fn, carrier);
        },
        createTiveCarrier({commit}, carrier) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveCarriers')
                this.$api.tivecarriers.create(carrier, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveCarrier', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveCarrier({commit}, carrier) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveCarriers')
                this.$api.tivecarriers.update(carrier.carrierId, carrier, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveCarrier')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveCarrier({commit}, carrierId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveCarriers')
                this.$api.tivecarriers.delete(carrierId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteTiveCarrier', carrierId)
                            else commit('updateTiveCarrier', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        settiveCarriersFullLoad(state, FullLoad) {
            state.tiveCarriersFullLoad = state.tiveCarriersFullLoad || FullLoad
        },
        setTiveCarriersLiteLoad(state, LitaLoad) {
            state.tiveCarriersLiteLoad = state.tiveCarriersLiteLoad || LitaLoad
        },

        setTiveCarriers(state, nTiveCarriers) {
            nTiveCarriers = nTiveCarriers.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveCarriers.sort(sortByName)
            state.tiveCarriers = nTiveCarriers
        },

        updateTiveCarriers(state, nTiveCarriers) {
            if (!state.tiveCarriers.length) {
                nTiveCarriers = nTiveCarriers.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveCarriers.sort(sortByName)
                state.tiveCarriers = nTiveCarriers
                // const chunks = arraySplitIntoChunks(nTiveCarriers)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveCarriers.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveCarriers.forEach(function (nCarrier) {
                if (nCarrier?.name) nCarrier.name_ = nCarrier.name.toLocaleLowerCase()
                let i = state.tiveCarriers.findIndex(u => (u.carrierId == nCarrier.carrierId))
                if (i < 0) {
                    state.tiveCarriers.push(nCarrier) //(Object.freeze(nCarrier))
                } else {
                    // if (!state.tiveCarriersFullLoad || state.tiveCarriers[i][changedField] != nCarrier[changedField]) {
                    updateObjectByDiff(state.tiveCarriers[i], nCarrier)
                    // delete nCarrier.carrierId
                    // nCarrier = {...state.tiveCarriers[i], ...nCarrier}
                    // state.tiveCarriers[i] = nCarrier //Object.freeze(nCarrier)
                }
            })

        },
        updateTiveStandardizedCarriers(state, nTiveCarriers) {
            if (!state.tiveStandardizedCarriers.length) {
                nTiveCarriers = nTiveCarriers.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveCarriers.sort(sortByName)
                state.tiveStandardizedCarriers = nTiveCarriers
                // const chunks = arraySplitIntoChunks(nTiveCarriers)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveCarriers.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveCarriers.forEach(function (nCarrier) {
                if (nCarrier?.name) nCarrier.name_ = nCarrier.name.toLocaleLowerCase()
                let i = state.tiveStandardizedCarriers.findIndex(u => (u.id == nCarrier.id))
                if (i < 0) {
                    state.tiveStandardizedCarriers.push(nCarrier) //(Object.freeze(nCarrier))
                } else {
                    // if (!state.tiveCarriersFullLoad || state.tiveCarriers[i][changedField] != nCarrier[changedField]) {
                    updateObjectByDiff(state.tiveStandardizedCarriers[i], nCarrier)
                    // delete nCarrier.carrierId
                    // nCarrier = {...state.tiveCarriers[i], ...nCarrier}
                    // state.tiveCarriers[i] = nCarrier //Object.freeze(nCarrier)
                }
            })

        },
        filterTiveCarriers(state, nTiveCarriers) {
            // let Ids = state.tiveCarriers.map(u=> u.carrierId)
            let nIds = nTiveCarriers.map(u => u.carrierId)
            let removedIds = state.tiveCarriers.filter(u => !nIds.includes(u.carrierId)).map(u => u.carrierId)
            removedIds.forEach(removedId => {
                let i = state.tiveCarriers.findIndex(u => (u.carrierId == removedId))
                if (i != -1) {
                    state.tiveCarriers.splice(i, 1)
                }
            })
        },
        updateTiveCarrier(state, nCarrier) {
            if (nCarrier?.name) nCarrier.name_ = nCarrier.name.toLocaleLowerCase()
            let i = state.tiveCarriers.findIndex(u => (u.carrierId == nCarrier.carrierId))
            if (i < 0) {
                state.tiveCarriers.push(nCarrier) //(Object.freeze(nCarrier))
            } else {
                // if (state.tiveCarriersFullLoad || state.tiveCarriers[i][changedField] != nCarrier[changedField]) {
                updateObjectByDiff(state.tiveCarriers[i], nCarrier)
                // delete nCarrier.carrierId
                // nCarrier = {...state.tiveCarriers[i], ...nCarrier}
                // state.tiveCarriers[i] = nCarrier //Object.freeze(nCarrier)
            }
        },
        deleteTiveCarrier(state, carrierId) {
            let i = state.tiveCarriers.findIndex(u => (u.carrierId == carrierId))
            if (i != -1) {
                state.tiveCarriers.splice(i, 1)
            }
        },

        clearTiveCarriers(state) {
            state.tiveCarriers = []
            state.tiveCarriersFullLoad = false
        },
    },
    getters: {
        isTiveCarriersFullLoad(state) {
            return state.tiveCarriersFullLoad
        },
        isTiveCarriersLiteLoad(state) {
            return state.tiveCarriersLiteLoad
        },
        tiveStandardizedCarriers(state) {
            return state.tiveStandardizedCarriers
        },
        tiveCarriers(state) {
            return state.tiveCarriers
        },
        tiveCarriersByIds(state) {
            return state.tiveCarriers
                .reduce((tiveCarriersByIds, carrier) => {
                tiveCarriersByIds[carrier.carrierId] = carrier
                return tiveCarriersByIds
            }, {})
        },
        sortedTiveCarriersIds(state) {
            let tiveCarriers = state.tiveCarriers
            tiveCarriers.sort(sortByName)
            return tiveCarriers.map(u => u.carrierId)
        },
    }
}
