// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        geozonesGroupsLiteLoad: false,
        geozonesGroupsFullLoad: false,
        geozonesGroups: [],
    },
    actions: {
        fetchGeozonesGroups/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchGeozonesGroups', time: Date.now() / 1000})

                const params = getApiProps('geozones_groups', args)
                this.$api.geozonesgroups.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchGeozonesGroups', inprogress: false})
                    });
            })
        },
        fetchGeozonesGroupsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getGeozonesGroups()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setGeozonesGroups', response.data)
                            commit('setGeozonesGroupsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeozonesGroupsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeozonesGroupsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isGeozonesGroupsLiteLoad) {
                    return resolve(getters.geozonesGroups.length)
                }
                dispatch('fetchGeozonesGroups', {lite: true})
                    .then((data) => {
                        commit('updateGeozonesGroups', data)
                        commit('setGeozonesGroupsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchGeozonesGroupsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchGeozonesGroupsAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchGeozonesGroupsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchGeozonesGroupsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.geozonesGroups.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.geozonesGroups.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchGeozonesGroups', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateGeozonesGroups', data)
                            })
                            .catch(() => {
                                dispatch('fetchGeozonesGroups', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setGeozonesGroupsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchGeozonesGroupsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchGeozonesGroupsChanged', inprogress: false})
                })
        },
        fetchGeozonesGroupsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isGeozonesGroupsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchGeozonesGroupsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchGeozonesGroups', args)
                .then((data) => {
                    commit('updateGeozonesGroups', data)
                    return dispatch('fetchGeozonesGroups', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterGeozonesGroups', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchGeozonesGroupsChanged', inprogress: false})
                });
        },
        reloadGeozonesGroupsAll({commit, dispatch}, args) {
            commit('clearGeozonesGroups')
            return dispatch('fetchGeozonesGroupsAllLite', args)
                .then(() => {
                    dispatch('fetchGeozonesGroupsAllPages', args)
                })
        },

        saveGeozonesGroup({dispatch}, geozonesGroup) {
            let fn = (geozonesGroup.id) ? 'updateGeozonesGroup' : 'createGeozonesGroup'
            return dispatch(fn, geozonesGroup);
        },
        createGeozonesGroup({commit, dispatch}, geozonesGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones_groups')
                this.$api.geozonesgroups.create(geozonesGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateGeozonesGroup', response.data)
                            dispatch('fetchGeozonesGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateGeozonesGroup({commit, dispatch}, geozonesGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones_groups')
                this.$api.geozonesgroups.update(geozonesGroup.id, geozonesGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateGeozonesGroup', response.data)
                            dispatch('fetchGeozonesGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteGeozonesGroup({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('geozones_groups')
                this.$api.geozonesgroups.delete(id, {...params})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteGeozonesGroup', id)
                            dispatch('fetchGeozonesGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setGeozonesGroupsLiteLoad(state, LiteLoad) {
            state.geozonesGroupsLiteLoad = state.geozonesGroupsLiteLoad || LiteLoad
        },
        setGeozonesGroupsFullLoad(state, FullLoad) {
            state.geozonesGroupsFullLoad = state.geozonesGroupsFullLoad || FullLoad
        },

        setGeozonesGroups(state, nGeozonesGroups) {
            nGeozonesGroups = nGeozonesGroups.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nGeozonesGroups.sort(sortByName)
            state.geozonesGroups = nGeozonesGroups
        },

        updateGeozonesGroups(state, nGeozonesGroups) {
            if (!state.geozonesGroups.length) {
                nGeozonesGroups = nGeozonesGroups.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nGeozonesGroups.sort(sortByName)
                state.geozonesGroups = nGeozonesGroups
                // const chunks = arraySplitIntoChunks(nGeozonesGroups)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.geozonesGroups.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nGeozonesGroups.forEach(function (nGeozonesGroup) {
                if (nGeozonesGroup?.name) nGeozonesGroup.name_ = nGeozonesGroup.name.toLocaleLowerCase()
                let i = state.geozonesGroups.findIndex(u => (u.id == nGeozonesGroup.id))
                if (i < 0) {
                    state.geozonesGroups.push(nGeozonesGroup) //(Object.freeze(nGeozonesGroup))
                } else
                if (!state.geozonesGroupsFullLoad || state.geozonesGroups[i][changedField] != nGeozonesGroup[changedField]) {
                    updateObjectByDiff(state.geozonesGroups[i], nGeozonesGroup)
                    // delete nGeozone.id
                    // nGeozonesGroup = {...state.geozonesGroups[i], ...nGeozonesGroup}
                    // state.geozonesGroups[i] = nGeozonesGroup //Object.freeze(nGeozonesGroup)
                }
            })
        },
        filterGeozonesGroups(state, nGeozonesGroups) {
            // let Ids = state.geozonesGroups.map(u=> u.id)
            let nIds = nGeozonesGroups.map(u => u.id)
            let removedIds = state.geozonesGroups.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.geozonesGroups.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.geozonesGroups.splice(i, 1)
                }
            })
        },
        updateGeozonesGroup(state, nGeozonesGroup) {
            if (nGeozonesGroup?.name) nGeozonesGroup.name_ = nGeozonesGroup.name.toLocaleLowerCase()
            let i = state.geozonesGroups.findIndex(u => (u.id == nGeozonesGroup.id))
            if (i < 0) {
                state.geozonesGroups.push(nGeozonesGroup) //(Object.freeze(nGeozonesGroup))
            } else
            if (!state.geozonesGroupsFullLoad || state.geozonesGroups[i][changedField] != nGeozonesGroup[changedField]) {
                updateObjectByDiff(state.geozonesGroups[i], nGeozonesGroup)
                // delete nGeozone.id
                // nGeozonesGroup = {...state.geozonesGroups[i], ...nGeozonesGroup}
                // state.geozonesGroups[i] = nGeozonesGroup //Object.freeze(nGeozonesGroup)
            }
        },

        deleteGeozonesGroup(state, id) {
            let i = state.geozonesGroups.findIndex(u => (u.id == id))
            if (i != -1) {
                state.geozonesGroups.splice(i, 1)
            }
        },

        clearGeozonesGroups(state) {
            state.geozonesGroups = []
            state.geozonesGroupsLiteLoad = false
            state.geozonesGroupsFullLoad = false
        },
    },
    getters: {
        isGeozonesGroupsLiteLoad(state) {
            return state.geozonesGroupsLiteLoad
        },
        isGeozonesGroupsFullLoad(state) {
            return state.geozonesGroupsFullLoad
        },
        geozonesGroups(state) {
            return state.geozonesGroups
        },
        geozonesGroupsByIds(state) {
            return state.geozonesGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g
                return groupsByIds
            }, {})
        },
        sortedGeozonesGroupsIds(state) {
            let geozonesGroups = state.geozonesGroups
            geozonesGroups.sort(sortByName)
            return geozonesGroups.map(u => u.id)
        },
    }
}
