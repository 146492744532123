<template>
    <div class="unit-info-prometheus show-info"
         :class="[!isSectionInfoOpen && 'show-info--close']"
    >
        <button class="button button-close"
                :class="[!isSectionInfoOpen && 'button-close-info']"
                @click="toggleList"
        >
            <SvgIcon name="buble-arrow" class="icon" v-if="!isSectionInfoOpen"/>
        </button>

        <div class="unit-info-prometheus__header page-block">
            <div class="unit-info-prometheus__box" style="flex-direction: column">
                <div class="unit-info-prometheus__box-item flex align-items-canter flex-gap-10">
                    <span class="unit-info-prometheus__icon">
                        <SvgIcon name="car" class="icon"/>
                    </span>
                    <div class="unit-info-prometheus__title">{{unit.name}}:</div>
                </div>
                <UnitInfo_indicators
                    class="unit-info-prometheus__box-item flex align-items-canter flex-gap-10"
                    :unitId="unitId"
                    :indicators="pageParams.indicators"
                />
            </div>
            <div class="unit-info-prometheus__box unit-info-prometheus__address">
                <UnitInfo__address :unitId="unitId"/>
            </div>
        </div>

        <UnitInfo__sensors
            class="unit-info-prometheus__sensors page-block"
            :sensors="pageParams.sensors"
            :hideSensorByValue="true"
            :unitId="unitId"
        />

        <!--    border    -->
        <div class="default-border-bottom page-block"></div>

        <TabsControlByRoute class="page-block" :list="pageParams.menuList"/>

        <div class="default-border-bottom page-block"></div>

        <div id="sectionInfoByPage" class="page-block"></div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import UnitInfo_indicators from "@/components/units/info/UnitInfo_indicators.vue";
import UnitInfo__address from "@/components/units/info/UnitInfo__address.vue";
import TabsControlByRoute from "@/components/_base/TabsControlByRoute.vue";
import UnitInfo__sensors from "@/components/units/info/UnitInfo__sensors.vue";
import consts from "@/consts";

export default {
    setup() {
        return {
            defaultAccordionInfo: ['sensors'],
        }
    },
    name: "UnitInfo_info.prometheus",
    props: {
        unitId: [String, Number],
    },
    components: {
        TabsControlByRoute,
        UnitInfo__address,
        UnitInfo_indicators,
        UnitInfo__sensors,
    },
    data() {
        return {
            activeType: 'video',
            component: '',
            components: [
                {id: '', name: ''}
            ],
            isSectionInfoOpen: true,
        }
    },
    computed: {
        ...mapGetters([
            "unitsByIds",
            "unitsLmsgsByIds",
            "isUnitsFullLoad",
        ]),
        routeName() {
            return this.$route.name
        },
        routeMeta() {
            return this.$route.meta
        },
        routeFilter() {
            return this.$route.params
        },
        setRoute() {
            return this.routeMeta.setRoute
        },
        routeTemplate() {
            return this.routeFilter.template
        },
        routesList() {
            let obj = {
                'info': {name: this.$t('Unit.menu.Info'), route_name: consts.routerNames?.units?.type[this.routeTemplate]?.info, setRoute: () => this.setRoute.unitId(this.$route, this.$router, this.unitId),icon: 'common__info'},
                'track': {name: this.$t('Unit.menu.Track'), route_name: consts.routerNames.units.track.main, setRoute: () => this.setRoute.track(this.$route, this.$router, this.unitId), icon: 'build-track'},
                'report': {name: this.$t('Unit.menu.Report'), route_name: '', icon: 'reports'},
                'fuel': {name: this.$t('Unit.menu.Fuel'), route_name: '', icon: 'tab-fuel'},
                'chart': {name: this.$t('Unit.menu.Chart'), route_name: '', icon: 'chart'},
                'edit': {name: this.$t('Unit.menu.Edit'), route_name: consts.routerNames.units.edit.main, icon: 'command__edit'},
            }

            if(this.isUnitsFullLoad) {
                obj.edit['setRoute'] = () => this.setRoute.unitEdit(this.$route, this.$router, this.unitId)
            }

            return obj;
        },
        pageParamsByUnitType() {
            return {
                default: {
                    menuList: [this.routesList.info],
                    indicators: ['connection']
                },
                xvision : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'camera', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
                xtrack : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
                xcool : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
                xpower : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
                xcargo : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
                xlink : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
                pro_cargo_vision : {
                    menuList: [this.routesList.info, this.routesList.track, this.routesList.edit],//this.routesList.report, this.routesList.chart,
                    indicators: ['connection', 'movement', 'camera', 'ignition'],
                    sensors: ['last_message', 'imei', 'speed', 'brand', 'satelletes', 'year'],
                },
            }
        },
        hideInfoUnit() {
            return this.routeMeta.hideInfoUnit
        },
        pageParams() {
            return this.pageParamsByUnitType[this.routeFilter.template] || this.pageParamsByUnitType.default
        },
        unit() {
            return this.unitsByIds[this.unitId] || {};
        },
        unitLmsg() {
            return this.unitsLmsgsByIds[this.unitId];
        },
    },
    watch: {
        routeName: {
            handler: function () {
                this.isSectionInfoOpen = !this.hideInfoUnit
            }
        }
    },
    methods: {
        toggleList() {
            this.isSectionInfoOpen = !this.isSectionInfoOpen
            // this.setRoute.unitId(this.$route, this.$router, this.unitId)
        }
    },
    mounted() {
        this.isSectionInfoOpen = !this.hideInfoUnit
    }
}
</script>

<style lang="scss">
.unit-info-prometheus {
    padding: 13px;
    background: var(--unit-info-bg);
    border: var(--unit-info-border);
    box-shadow: var(--unit-info-box-shadow);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
    position: relative;
    @include scroll(var(--unit-info-bg), var(--section-list-item-active-bg), 6px);

    &__title {
        @include font(12px, normal, 600, none, normal, var(--color-default));
        @include adaptive-font(12, 11);
    }

    &__icon {
        color: var(--color-default);
    }

    &__box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 5px;

        padding: 10px 0;

        &:first-child {
            border-bottom: solid 1px var(--border-color);
        }

        .unit-indicators__item:last-child {
            justify-content: flex-end;
        }
    }

    &__box-item {
        //max-width: 50%;
        flex-wrap: wrap;
    }

    .car-info {
        display: flex;

        .address-info {
            display: flex;
            gap: 10px;
        }

        .info-text {
            display: none;
        }

        .block-title {
            @include font(10px, normal, 500, none, normal, var(--color-default));
            @include adaptive-font(10, 10);
        }

        .address-info__icon {
            color: var(--color-default);
        }
    }

    &__item-page {
        padding: 10px 0;

        .car-info-dashboard {
            overflow: auto;
            @include scroll(var(--color-neutral-25), var(--color-neutral-100), 6px);
            max-height: 250px;
        }

        .car-info-dashboard__list {
            flex-direction: column;
        }

        .car-info-dashboard__icon {
            display: block;
            margin-right: 5px;
        }

        .car-info-dashboard__item {
            width: 100%;
        }

        .car-info-dashboard__text-wrap {
            display: flex;
            gap: 5px;
        }
    }

    &__notifications-badge {
        padding: 2px 5px;
        background: var(--color-secondary-500);
        @include font(8px, normal, 700, none, normal, var(--color-white));
        border-radius: 100%;
        position: absolute;
        top: -5px;
        right: -5px;
    }

    &__notifications {
        max-height: 74px;
        overflow: auto;
        @include scroll(var(--color-neutral-25), var(--color-neutral-100), 6px);

        &__item {
            padding-left: 30px;
            @include font(10px, normal, 400, none, normal, var(--color-font-description));
            @include adaptive-font(10, 10);
            display: flex;
            align-items: center;
            gap: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;

            span {
                &:last-child {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            //flex-wrap: wrap;
        }
    }

    .dynamic-scroller-list-track {
        height: auto;
    }

    .tabs-control {
        &__item {
            font-size: 10px;
        }
    }

    .car-info-dashboard {
        padding: 0;
    }

    .car-info-dashboard__list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .car-info-dashboard__item {
        width: calc(50% - 8px);
        padding-left: 30px;
        display: flex;
        align-items: flex-start;
        gap: 3px;
    }

    .sensors-title {
        @include font(10px, normal, 600, none, normal, var(--color-default));
        @include adaptive-font(10, 10)
    }

    .sensors-value {
        @include font(10px, normal, 400, none, normal, var(--color-font-description));
        @include adaptive-font(10, 10)
    }

    .car-info-dashboard__icon {
        display: none;
    }

    #sectionInfoByPage {
        height: 100%;

        .section__head {
            display: none;
        }
        .section__content-wrapper {
            grid-template-rows: 1fr 50px;
        }
        .section__body {
            grid-template-rows: auto 1fr;
            background: none;
        }
        .section__footer {
            box-shadow: none;
            background: none;
        }
        .btn-cancel {
            display: none;
        }
    }
}
.show-info {
    width: 350px;
}
.show-info--close {
    width: 45px;
    overflow: hidden;

    #sectionInfoByPage {
        display: none;
    }
    .page-block {
        display: none;
    }
}
.button {
    &.button-info-open {
        position: absolute;
        left: 100%;
        top: 10px;
        width: 18px;
        height: 18px;
        background: black;
        z-index: 10;
    }
    &.button-close {
        z-index: 10;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.button-close-info {
        //background: none;
        background: #E8F1F8;
        box-shadow: 0px 2px 4px 0px rgba(69, 125, 191, 0.50);
        right: 10px;
        border-radius: 8px;
        .icon {
            //width: 24px;
            //height: 24px;
        }
        &:before,
        &:after {
            content: none;
        }
    }
}
</style>