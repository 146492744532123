// import Vue from 'vue'
import consts from "@/consts"

export default {
    state: {
        map: {
            bounds: null,
            mapPaddingBounds: null,
            zoom: null,
            ...consts.map,

            unitsOnMap: [],
            showUnitTrack: {
                unitId: null,
                date: null,
            },
            geoitemEdit: null,
            geoitemsOnMap: [],
            showNotificationEvent: null,
            showShipmentEvent: null,
        },
    },
    actions: {
        setMapCenter({commit}, value) {
            commit('setMapCenter', value)
        },
        setMapView({commit}, args) {
            commit('setMapCenter', args.latlng)
            commit('setMapZoom', args.zoom)
        },
        setMapBounds({commit}, value) {
            commit('setMapBounds', value)
        },
        setMapPaddingBounds({commit}, value) {
            commit('setMapPaddingBounds', value)
        },
        setMapBoundsByUnits({commit, rootState}, units) {
            if (!units || !units.length) return false
            let Lmsgs = rootState.units_lmsgs.unitsLmsgs
            let bounds = units.reduce((latlngList, id) => {
                let lmsg = Lmsgs.find(lmsg => lmsg.id_unit == id)
                if (lmsg && lmsg.latlng) {
                    latlngList.push(lmsg.latlng)
                }
                return latlngList
            }, [])
            if (bounds.length > 1) {
                commit('setMapBounds', bounds)
            } else
            if (bounds.length == 1) {
                commit('setMapCenter', bounds[0])
            }
            //commit('setUnitsShowOnMap', { showOnMap: true, unitIds: units })
        },
        setMapBoundsByGeozones({commit, rootState}, geoitems) {
            if (!geoitems || !geoitems.length) return false
            let geozones = rootState.geozones.geozones
            let bounds = geoitems.reduce((points, id) => {
                let geozone = geozones.find(geo => geo.id == id)
                if (geozone && geozone.points.length) {
                    let rd = geozone.radius / 111200
                    points.push([geozone.points[0][0] * 1.0 + 0, geozone.points[0][1] * 1.0 + 0])
                    points.push([geozone.points[0][0] * 1.0 + rd, geozone.points[0][1] * 1.0 + rd])
                    points.push([geozone.points[0][0] * 1.0 - rd, geozone.points[0][1] * 1.0 - rd])
                }
                return points
            }, [])
            if (bounds.length > 1) {
                commit('setMapBounds', bounds)
            } else
            if (bounds.length == 1) {
                commit('setMapCenter', bounds[0])
            }
        },

        setGeoitemShowOnMap({commit}, args) {
            commit('setGeoitemShowOnMap', args)
        },
        setGeoitemsShowOnMap({commit}, args) {
            commit('setGeoitemsShowOnMap', args)
        },

        setUnitShowOnMap({commit}, args) {
            commit('setUnitShowOnMap', args)
        },
        setUnitsShowOnMap({commit}, args) {
            commit('setUnitsShowOnMap', args)
        },
        setAllUnitsShowOnMap({ commit/*, rootState*/ }, args) {
            //args.unitIds = (!args.showOnMap) ? [] : rootState.units.units_index
            commit('setAllUnitsShowOnMap', args)
        },
        //remove
        setShowUnitTrack({commit}, args) {
            commit('setShowUnitTrack', args)
        },
        setGeoitemEdit({commit}, args) {
            commit('setGeoitemEdit', args)
        },
        patchGeoitemEdit({commit}, args) {
            commit('patchGeoitemEdit', args)
        },
        setEventShowOnMap({commit}, event) {
            commit('setEventShowOnMap', event)
            if (event && event.latlng) {
                commit('setMapCenter', event.latlng)
            }
        },
        setShipmentEventShowOnMap({commit}, event) {
            commit('setShipmentEventShowOnMap', event)
            if (event && event.latlng) {
                commit('setMapCenter', event.latlng)
            }
        },
    },
    mutations: {
        setEventShowOnMap(state, event) {
            state.map.showNotificationEvent = !event ? null : {
                xid: '',
                type: '',       //notification-event
                latlng: null,   //[]
                image: '',
                message: '',    //message || text
                text: '',
                ...event
            }
        },
        setShipmentEventShowOnMap(state, event) {
            state.map.showShipmentEvent = !event ? null : {
                xid: '',
                type: '',       //notification-event
                latlng: null,   //[]
                image: '',
                message: '',    //message || text
                text: '',
                ...event
            }
        },

        setGeoitemEdit(state, geoitem) {
            state.map.geoitemEdit = geoitem
        },
        patchGeoitemEdit(state, patch) {
            if (state.map.geoitemEdit) {
                state.map.geoitemEdit = {
                    ...state.map.geoitemEdit,
                    ...patch
                }
            }
        },

        setShowUnitTrack(state, args) {
            state.map.showUnitTrack = {
                unitId: args.unitId,
                date: args.date,
            };
        },
        setMapCenter(state, center) {
            // console.log('setMapCenter', center)
            if (typeof center === 'string') {
                center = center.split(',')
            }
            if (Array.isArray(center) && center.length === 2) {
                state.map.center = {lat: center[0], lng: center[1]}
            } else
            if (center.lat && center.lng) {
                state.map.center = {lat: center.lat, lng: center.lng}
            } else {
                console.warn('setMapCenter', center)
            }
        },
        setMapZoom(state, value) {
            state.map.zoom = value
        },
        setMapBounds(state, value) {
            state.map.bounds = value
        },
        setMapPaddingBounds(state, value) {
            state.map.mapPaddingBounds = Object.freeze(value)
        },
        setGeoitemShowOnMap(state, args) {
            if (!args.showOnMap) {
                state.map.geoitemsOnMap = state.map.geoitemsOnMap.filter(id => {
                    return id !== args.geoitemId
                })
            } else
            if (/*args.showOnMap &&*/ !state.map.geoitemsOnMap.includes(args.geoitemId)) {
                state.map.geoitemsOnMap.push(args.geoitemId)
            }
        },
        setGeoitemsShowOnMap(state, args) {
            if (!args.showOnMap) {
                state.map.geoitemsOnMap = state.map.geoitemsOnMap.filter(id => {
                    return !args.geoitemIds.includes(id)
                })
            } else {
                let ids = args.geoitemIds.filter(id => {
                    return !state.map.geoitemsOnMap.includes(id)
                })
                state.map.geoitemsOnMap.push(...ids)
            }
        },
        setAllGeoitemsShowOnMap(state, geoitemIds) {
            state.map.geoitemsOnMap = geoitemIds
        },

        setUnitShowOnMap(state, args) {
            if (!args.showOnMap) {
                state.map.unitsOnMap = state.map.unitsOnMap.filter(id => {
                    return id !== args.unitId
                })
            } else
            if (/*args.showOnMap &&*/ !state.map.unitsOnMap.includes(args.unitId)) {
                state.map.unitsOnMap.push(args.unitId)
            }
        },
        setUnitsShowOnMap(state, args) {
            if (!args.showOnMap) {
                state.map.unitsOnMap = state.map.unitsOnMap.filter(id => {
                    return !args.unitIds.includes(id)
                })
            } else {
                let ids = args.unitIds.filter(id => {
                    return !state.map.unitsOnMap.includes(id)
                })
                state.map.unitsOnMap.push(...ids)
            }
        },
        setAllUnitsShowOnMap(state, unitIds) {
            state.map.unitsOnMap = [...unitIds]
        },


    },
    getters: {
        getEventShowOnMap(state) {
            return state.map.showNotificationEvent
        },
        getShipmentEventShowOnMap(state) {
            return state.map.showShipmentEvent
        },
        getGeoitemEdit(state) {
            return state.map.geoitemEdit
        },
        getMapCenter(state) {
            return state.map.center
        },
        getMapZoom(state) {
            return state.map.zoom
        },
        getMapBounds(state) {
            return state.map.bounds
        },
        getMapPaddingBounds(state) {
            return state.map.mapPaddingBounds
        },

        geoitemsOnMap(state) {
            return state.map.geoitemsOnMap
        },
        unitsOnMap(state) {
            return state.map.unitsOnMap
        },


    }
}
