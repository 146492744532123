const RESOURCE_NAME = '';//'/auth/';
import axios from "@/api/http-common";
import config from "@/config";
const baseURL = config.api.baseURL

export default () => ({
    register(data, params) {
        return axios.post(`${RESOURCE_NAME}/register`, data, {params, baseURL});
    },
    forgotPassword(data, params) {
        return axios.post(`${RESOURCE_NAME}/password/forgot`, data, {params, baseURL});
    },
    resetPassword(data, params) {
        return axios.post(`${RESOURCE_NAME}/password/reset`, data, {params, baseURL});
    }
});