<template>
    <template v-if="!progress">
        <div class="lmsg-history-time-ago" style="padding-left: 20px">
            {{ $t('Updated at {time}', {time}) }}
            ({{_timeAgo}})
        </div>
        <table class="lmsg-history-table">
            <thead>
            <tr>
                <th class="lmsg-history-table__title">{{ $t('text.Date and Time')}}</th>
                <th class="lmsg-history-table__title">{{ $t('text.Coordinates') }}</th>
                <th class="lmsg-history-table__title">{{ $t('text.Speed') }}</th>
                <th class="lmsg-history-table__title">{{ $t('text.Params') }} </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in history" :key="i">
                <td valign="top">
                    <div class="white-space-no-wrap">{{ formatDateTime(item.time?.utc) }}</div>
                </td>
                <td valign="top" class="lmsg-history-table__latlng">
                    <div v-if="item.latlng" class="flex white-space-no-wrap">
                        {{latLngOutput(item.latlng)}}
                        <button class="button button_command" @click="_copyUnitAddress2Clipboard(item)">
                            <SvgIcon name="common__copy_sm"/>
                        </button>
                    </div>
                    <div v-else class="text-align-center">-</div>
                </td>
                <td valign="top" class="white-space-no-wrap">{{ Math.round(item.speed) + ' ' + speedUnits }}</td>
                <td valign="top">
                    <div v-if="item.params">
                        <div v-for="(value, key, j) in item.params" :key="j">
                            {{key}}: {{value}}
                        </div>
                    </div>
                    <div v-else class="text-align-center">-</div>
                </td>
            </tr>
            </tbody>
        </table>
    </template>
    <SectionLoading v-if="progress"/>
</template>

<script>
// import moment from "moment";
import {mapGetters} from "vuex";
import {copyUnitAddress2Clipboard} from '@/lib/unit'
import {calcUTCOffset, formatTimeAgo, formatTimestamp, latLngOutput} from "@/lib/lib";
import {useToast} from "vue-toastification";
import moment from "moment-timezone";

export default {
    setup() {
        return {
            latLngOutput
        }
    },
    name: "LastNMsgsOfUnitTable",
    props: {
        unitId: [String, Number],
        limit: {
            type: Number,
            default: () => 10,
        }
    },
    components: {},
    data() {
        return {
            progress: true,
            history: [],
            toast: useToast(),
            timeAgo: 0,
            timeAgoInterval: null,
            now: Math.round(Date.now() / 1000),
        }
    },
    computed:{
        ...mapGetters([
            "getDateFormat",
            "getTimeFormat",
            "getAppUser",
            "unitsLmsgsTimeByIds",
            "getAppUserUnits",
        ]),
        speedUnits(){
            return this.getAppUserUnits.speed
        },
        timezoneOffset(){
            return calcUTCOffset(this.getAppUser.time_zone*1, this.getAppUser.dst)
        },
        time() {
            return moment(this.now*1000).utcOffset(this.timezoneOffset/3600).format(this.getTimeFormat)
        },
        _timeAgo() {
            let time = formatTimeAgo(this.timeAgo)
            return this.$t('{time} ago', {time})
        },
    },
    watch: {
        unitId: {
            handler (newVl /*, oldVl*/) {
                this.history = []
                if (newVl) {
                    this.fetchLastMsgsUnitHistory(newVl)
                }
            },
            immediate: true
        }
    },
    methods: {
        fetchLastMsgsUnitHistory(unitId) {
            if(!unitId) return false;
            this.$api.unitsmsgs.getLast(unitId, this.limit, {withSensors: 0, withAddress: 0})
            .then(resp => {
                this.history = resp.data || []
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() =>{
                this.progress = false
                this.now = Math.round(Date.now() / 1000)
            })
        },
        formatDateTime(timeUTC) {
            if(!timeUTC) return '-';
            let timeFormat = this.$consts.timeFormatsWithSeconds[this.getTimeFormat] || 'HH:mm:ss'
            return formatTimestamp(this.getAppUser.time_zone, this.getAppUser.dst, timeUTC, (this.getDateFormat + ' ' + timeFormat))
        },
        _copyUnitAddress2Clipboard(lmsg){
            copyUnitAddress2Clipboard(lmsg)
                .then(() => {
                    this.toast.success(this.$t('Copy done'));
                })
                .catch((er) => {
                    this.toast.warning(this.$t('Copy error'));
                    console.error('copy error', er);
                })
        },
    },
    updated() {
        // console.log('Updated: LastNMsgsOfUnitTable')
    },
    mounted() {
        this.timeAgoInterval = setInterval(() => this.timeAgo++, 1000)
        // console.log('mounted: LastNMsgsOfUnitTable')
    },
    beforeUnmount() {
        if(this.timeAgoInterval){
            clearInterval(this.timeAgoInterval)
        }
    }
}
</script>

<style scoped lang="scss">
.lmsg-history-table{
    border-spacing: 20px;
    border-collapse: separate;
    &__latlng {
        //text-align: center;
    }
    .button_command{
        min-height: 1.2rem;
        height: 1rem;
    }
    &__title {
        font-weight: 700;
    }
}
.lmsg-history-time-ago{
    display: flex;
    align-items: center;
    .button_command{
        background: gray;
    }
}
</style>