<template>
    <svg :class="className" xmlns="http://www.w3.org/2000/svg"
         :x-viewBox="iconViewBox"
         :x-width="width"
         :x-height="height"
    >
        <title v-if="title">{{ $te('Icons.'+title) ? $t('Icons.'+title) : title }}</title>
        <title v-else-if="false" :id="name" lang="en">{{ name }} icon</title>
        <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
    </svg>
</template>

<script>
import iconsTitles from '@/lib/icons-titles.js'
export default {
    name: 'svg-icon',
    props: {
        name: {
            type: String,
            required: true,
            default: () => 'empty'
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        title: {
            type: String,
            default: (props) => iconsTitles[props?.name] || ''
        },
    },
    computed: {
        icon() {
            let name = this.name || 'empty'
            let icon = require(`@/assets/icons/${name}.svg`);
            if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
                icon = icon.default;
            }
            return icon;
        },
        iconPath() {
            return this.icon.url;
        },
        iconViewBox() {
            return this.icon.viewBox;
        },
        className() {
            return 'svg-icon svg-icon--' + this.name;
        }
    }
};
</script>

<style lang="scss">
.svg-icon {
    fill: currentColor;
    height: 24px;
    width: 24px;
}
.prometheus-portal {
  .svg-icon {
    fill: currentColor;
    height: 18px;
    width: 18px;
  }
}
</style>
