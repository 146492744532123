<template>
    <div class="symbol__ignition-status">
        <span class="symbol__bg-icon icon" v-show="ignition">
            <span class="icon__symbol"><SvgIcon class="icon" name="units__ignition_bg"/></span>
        </span>
        <span class="symbol__ignition-icon icon" v-show="ignition">
            <span class="icon__symbol"><SvgIcon class="icon" name="units__ignition_symbol"/></span>
        </span>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'UnitsList_unit_ignition_status',
        props: [
            'unitId',
        ],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsLmsgsByIds",
            ]),
            unitLmsg(){
                return this.unitsLmsgsByIds[this.unitId]
            },
            ignition(){
                return !!this.unitLmsg?.status?.ignition?.value
            },
        },
        updated: function() {
           // console.log(`UnitsList_unit_ignition ${this.ignition} updated`);
        },
    }
</script>