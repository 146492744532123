const RESOURCE_NAME = '/user-customize';

export default axios => ({
    get(userId, params) {
        return axios.get(`${RESOURCE_NAME}/${userId}`, {params});
    },
    update(userId, data, params) {
        return axios.put(`${RESOURCE_NAME}/${userId}`, data, {params});
    },
    getAll(params) {
        return axios.get(`${RESOURCE_NAME}`, {params});
    },
    find(userId, params) {
        return axios.get(`${RESOURCE_NAME}/${userId}`, {params});
    },
    // find(userId, id, params) {
    //     return axios.get(`/users/${userId}${RESOURCE_NAME}/${id}`, {params});
    // },
    // update(userId, id, data, params) {
    //     return axios.put(`/users/${userId}${RESOURCE_NAME}/${id}`, data, {params});
    // },
});