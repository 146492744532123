// import Vue from 'vue'
//import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
//const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}

export default {
    state: {
        locatorUnitsIds: [],
        locatorTokenParams: {},
    },
    actions: {
        fetchLocatorAppUser({commit, dispatch}, args) {
            return new Promise((resolve, reject) => {
                if (!args.token) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLocatorAppUser', time: Date.now() / 1000})
                this.$api.locator.getAppUser(args)
                    .then((response) => {
                        let data = response.data || {}
                        commit('updateAppUser', data)
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLocatorAppUser', inprogress: false})
                    });
            })
        },
        fetchLocatorUnits({commit, dispatch}, args) {
            return new Promise((resolve, reject) => {
                if (!args.token) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLocatorUnits', time: Date.now() / 1000})
                this.$api.locator.getUnit(args)
                    .then((response) => {
                        let data = response.data || []
                        commit('updateUnits', data)
                        commit('setLocatorUnitsIds', data)
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLocatorUnits', inprogress: false})
                    });
            })
        },
        fetchLocatorUnitsLmsgs({commit, dispatch}, args) {
            return new Promise((resolve, reject) => {
                if (!args.token) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLocatorUnitsLmsgs', time: Date.now() / 1000})
                this.$api.locator.getUnitLmsg(args)
                    .then((response) => {
                        let data = response.data || []
                        commit('updateUnitsLmsgs', data)
                        // this.setUnitsShowOnMap({showOnMap: true, })
                        // this.setMapCenter(this.lmsg?.latlng)
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLocatorUnitsLmsgs', inprogress: false})
                    });
            })
        },
        fetchLocatorWeather({commit, dispatch}, args) {
            return new Promise((resolve, reject) => {
                if (!args.token) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLocatorWeather', time: Date.now() / 1000})
                this.$api.locator.getUnitWeather(args)
                    .then((response) => {
                        let data = response.data
                        commit('updateWeathers', data)
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLocatorWeather', inprogress: false})
                    });
            })
        },
        checkToken({commit, dispatch}, args) {
            return new Promise((resolve, reject) => {
                if (!args.token) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'checkToken', time: Date.now() / 1000})
                this.$api.locator.checkToken(args)
                    .then((response) => {
                        commit('setLocatorTokenParams', response.data)
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'checkToken', inprogress: false})
                    });
            })
        }
    },
    mutations: {
        setLocatorUnitsIds(state, units) {
            units = units.map(u => +u.id)
            state.locatorUnitsIds = units
        },
        setLocatorTokenParams(state, params) {
            state.locatorTokenParams = {...state.locatorTokenParams, ...params}
            if (params.error) state.locatorUnitsIds = []
        },
    },
    getters: {
        getLocatorUnitsIds(state) {
            return state.locatorUnitsIds
        },
        getLocatorTokenParams(state) {
            return state.locatorTokenParams
        }
    }
}
