<template>
    <div class="segment-control segment-control_type_icon"
         :class="segmentControlClass"
    >
        <ul class="segment-control__list"
            :class="segmentControlListClass"
        >
            <template v-for="item in list" :key="'segment-control__item-'+item.id">
                <li class="segment-control__item item"
                    :class="[segmentControlItemClass, (item.id == selected)?'item_active': '', (itemsErrors.includes(item.id)) && 'has-error']"
                    @click="selected = item.id"
                >
                    <div class="segment-control__item-customize"></div>
                    <div class="segment-control__item-content">
                        <span class="icon">
                            <span class="icon__symbol"><SvgIcon class="icon" :name="'wizard-settings'"/></span>
                        </span>
                        <span v-if="item.name">
                            {{item.name}}
                        </span>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SegmentControl_icon",
    emits:['update:modelValue'],
    props: {
        'segment-control-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'segment-control-list-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'segment-control-item-class': {
            type: [String, Array, Object],
            default: () => ''
        },
        'items': [Array, Object],
        'field_id': {
            type: [String],
            default: () => 'id'
        },
        'field_name': {
            type: [String],
            default: () => 'name'
        },
        itemsErrors: {
            type: Array,
            default: () => []
        },
        'modelValue': [Number, String],
    },
    data() {
        return {}
    },
    computed: {
        list() {
            let id = this.field_id
            let name = this.field_name
            let list = []
            if (Array.isArray(this.items)) {
                list = this.items.map(item => {
                    return {id: item[id], name: item[name]}
                })
            } else {
                //console.warn('items isn\'t array', (typeof this.items))
                const objectArray = Object.entries(this.items);
                objectArray.forEach(([id, item]) => {
                    let name = item;
                    if (typeof item == 'object' && item['name'] !== undefined) {//.hasOwnProperty
                        name = item['name']
                    }
                    if (typeof name == 'string') {
                        list.push({id, name})
                    } else {
                        console.warn('item isn\'t string', id, item)
                    }
                });
            }
            return list
        },
        selected: {
            get(){ return this.modelValue },
            set(v){ this.$emit('update:modelValue', v) }
        }
    },
    methods: {
    },
    created() {
    },
    updated() {
    },
    mounted() {
        //console.log('Select_icon', this, this.$config.img_baseURL)
    }
}
</script>

<style scoped>

</style>