// import consts from "@/consts";
// import store from '@/store;'
// import moment from "moment";
import statistics from '@/router/intuit/admin/statistics.js';
import users from '@/router/intuit/admin/users.js';
import units from '@/router/intuit/admin/units.js';
import recycleBin from '@/router/intuit/admin/recycleBin.js';
import userActions from '@/router/intuit/admin/userActions.js';
import objectChanges from '@/router/intuit/admin/objectChanges.js';

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    ...statistics,
    ...users,
    ...units,
    ...recycleBin,
    ...userActions,
    ...objectChanges,
    {//if route not found !!!
        path: '/:pathMatch(.*)*',
        name: 'null',
    }
];

export default routes;