const RESOURCE_NAME = '/notifications';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    getAllGroups(params) {
        return axios.get(`${RESOURCE_NAME}/groups`, {params});
    },
    //geopoints
    addGeopoints(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/geopoints/add`, data, {params});
    },
    removeGeopoints(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/geopoints/remove`, data, {params});
    },
    patchGeopoints(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/geopoints/${action}`, data, {params});
    },
    //geozones
    addGeozones(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/geozones/add`, data, {params});
    },
    removeGeozones(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/geozones/remove`, data, {params});
    },
    patchGeozones(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/geozones/${action}`, data, {params});
    },
    //units
    addUnits(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/units/add`, data, {params});
    },
    removeUnits(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/units/remove`, data, {params});
    },
    patchUnits(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/units/${action}`, data, {params});
    },
    //unit-groups
    addUnitGroups(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/unit-groups/add`, data, {params});
    },
    removeUnitGroups(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/unit-groups/remove`, data, {params});
    },
    patchUnitGroups(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/unit-groups/${action}`, data, {params});
    },
    //sensors
    addSensors(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/sensors/add`, data, {params});
    },
    removeSensors(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/sensors/remove`, data, {params});
    },
    patchSensors(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/sensors/${action}`, data, {params});
    },
    //routes
    addRoutes(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/routes/add`, data, {params});
    },
    removeRoutes(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/routes/remove`, data, {params});
    },
    patchRoutes(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/routes/${action}`, data, {params});
    },
    //drivers
    addDrivers(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/drivers/add`, data, {params});
    },
    removeDrivers(notifId, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/drivers/remove`, data, {params});
    },
    patchDrivers(notifId, action, data, params) {
        return axios.post(`${RESOURCE_NAME}/${notifId}/drivers/${action}`, data, {params});
    },
    //'unit-documents'
    //'driver-documents'
});