// import Vue from 'vue'
// import consts from "@/consts"
// import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
// const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.userId - b.userId;
}

export default {
    state: {
        tiveUsersFullLoad: false,
        tiveUsersLiteLoad: false,
        tiveUsers: [],
    },
    actions: {
        fetchTiveUsers/*all*/({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveUsers', time: Date.now() / 1000})

                const params = getApiProps('tiveUsers', args)
                this.$api.tiveusers.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveUsers', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        commit('setTiveUsersLiteLoad', true)
                        commit('settiveUsersFullLoad', true)
                        dispatch('setLastCall', {name: 'fetchTiveUsers', inprogress: false})
                    });
            })
        },
        fetchTiveUser/*all*/({dispatch, commit, getters}, id) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchTiveUser', time: Date.now() / 1000})

                const params = getApiProps('tiveUsers', {})
                this.$api.tiveusers.find(id, {...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveUser', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchTiveUser', inprogress: false})
                    });
            })
        },

        saveTiveUser({dispatch}, user) {
            let fn = (user?.userId) ? 'updateTiveUser' : 'createTiveUser'
            return dispatch(fn, user);
        },
        createTiveUser({commit}, user) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveUsers')
                this.$api.tiveusers.create(user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateTiveUser({commit}, user) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveUsers')
                this.$api.tiveusers.update(user.userId, user, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateTiveUser')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteTiveUser({commit}, userId) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('tiveUsers')
                this.$api.tiveusers.delete(userId, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteTiveUser', userId)
                            else commit('updateTiveUser', response.data)
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        settiveUsersFullLoad(state, FullLoad) {
            state.tiveUsersFullLoad = state.tiveUsersFullLoad || FullLoad
        },
        setTiveUsersLiteLoad(state, LitaLoad) {
            state.tiveUsersLiteLoad = state.tiveUsersLiteLoad || LitaLoad
        },

        setTiveUsers(state, nTiveUsers) {
            nTiveUsers = nTiveUsers.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nTiveUsers.sort(sortByName)
            state.tiveUsers = nTiveUsers
        },

        updateTiveUsers(state, nTiveUsers) {
            if (!state.tiveUsers.length) {
                nTiveUsers = nTiveUsers.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nTiveUsers.sort(sortByName)
                state.tiveUsers = nTiveUsers
                // const chunks = arraySplitIntoChunks(nTiveUsers)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.tiveUsers.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nTiveUsers.forEach(function (nUser) {
                if (nUser?.name) nUser.name_ = nUser.name.toLocaleLowerCase()
                let i = state.tiveUsers.findIndex(u => (u.userId == nUser.userId))
                if (i < 0) {
                    state.tiveUsers.push(nUser) //(Object.freeze(nUser))
                } else {
                    // if (!state.tiveUsersFullLoad || state.tiveUsers[i][changedField] != nUser[changedField]) {
                    updateObjectByDiff(state.tiveUsers[i], nUser)
                    // delete nUser.userId
                    // nUser = {...state.tiveUsers[i], ...nUser}
                    // state.tiveUsers[i] = nUser //Object.freeze(nUser)
                }
            })

        },
        filterTiveUsers(state, nTiveUsers) {
            // let Ids = state.tiveUsers.map(u=> u.userId)
            let nIds = nTiveUsers.map(u => u.userId)
            let removedIds = state.tiveUsers.filter(u => !nIds.includes(u.userId)).map(u => u.userId)
            removedIds.forEach(removedId => {
                let i = state.tiveUsers.findIndex(u => (u.userId == removedId))
                if (i != -1) {
                    state.tiveUsers.splice(i, 1)
                }
            })
        },
        updateTiveUser(state, nUser) {
            if (nUser?.name) nUser.name_ = nUser.name.toLocaleLowerCase()
            let i = state.tiveUsers.findIndex(u => (u.userId == nUser.userId))
            if (i < 0) {
                state.tiveUsers.push(nUser) //(Object.freeze(nUser))
            } else {
                // if (state.tiveUsersFullLoad || state.tiveUsers[i][changedField] != nUser[changedField]) {
                updateObjectByDiff(state.tiveUsers[i], nUser)
                // delete nUser.userId
                // nUser = {...state.tiveUsers[i], ...nUser}
                // state.tiveUsers[i] = nUser //Object.freeze(nUser)
            }
        },
        deleteTiveUser(state, userId) {
            let i = state.tiveUsers.findIndex(u => (u.userId == userId))
            if (i != -1) {
                state.tiveUsers.splice(i, 1)
            }
        },

        clearTiveUsers(state) {
            state.tiveUsers = []
            state.tiveUsersFullLoad = false
        },
    },
    getters: {
        isTiveUsersFullLoad(state) {
            return state.tiveUsersFullLoad
        },
        isTiveUsersLiteLoad(state) {
            return state.tiveUsersLiteLoad
        },
        tiveUsers(state) {
            return state.tiveUsers
        },
        tiveUsersByIds(state) {
            return state.tiveUsers
                .reduce((tiveUsersByIds, user) => {
                tiveUsersByIds[user.userId] = user
                return tiveUsersByIds
            }, {})
        },
        sortedTiveUsersIds(state) {
            let tiveUsers = state.tiveUsers
            tiveUsers.sort(sortByName)
            return tiveUsers.map(u => u.userId)
        },
    }
}
