<template>
    <section>
        <div class="car-info">
            <div class="address-info">
                <div class="address-info__icon">
                    <SvgIcon class="icon main-icon" :name="icon"/>
                </div>
                <div class="address-info__body">
                    <span class="info-text">{{ $t('Unit.Address') }}</span>
                    <h3 class="block-title" :title="address" v-if="unitId">{{address}}</h3>
                    <SkeletorLoader width="100%" height="40" as="div" pill v-else/>
                </div>
            </div>
            <button class="button button_command address-info__button"
                    @click="_copyUnitAddress2Clipboard(unitLmsg)"
                    v-if="latLng"
            >
                <SvgIcon name="common__copy"/>
            </button>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import {copyUnitAddress2Clipboard} from "@/lib/unit.js";

export default {
    setup() {
        return {
            copyUnitAddress2Clipboard
        }
    },
    name: "UnitInfo__address",
    props:{
        'unitId': [Number,String]
    },
    data(){
        return {
            toast: useToast(),
        }
    },
    computed:{
        ...mapGetters([
            "unitsLmsgsByIds",
        ]),
        unitLmsg(){
            return this.unitsLmsgsByIds[this.unitId] || {}
        },
        address(){
            return this.unitLmsg?.address || ''
        },
        lbsLocation(){
            return this.unitLmsg?.lbs_location || 0
        },
        latLng() {
            return this.unitLmsg?.latlng
        },
        icon(){
            return this.lbsLocation ? 'cell_tower' : 'unit_dashboard__address-point'
        },
    },
    methods: {
        _copyUnitAddress2Clipboard(lmsg){
            this.copyUnitAddress2Clipboard(lmsg)
                .then(() => {
                    this.toast.success(this.$t('Copy done'));
                })
                .catch((er) => {
                    this.toast.warning(this.$t('Copy error'));
                    console.error('copy error', er);
                })
        },
    },
}
</script>

<style scoped lang="scss">
.block-title{
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>